import { Link } from 'react-router-dom';
import ArrowLeft from '../_styles/images/ArrowLeft.png';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import * as actionTypes from '../redux/actionTypes';
import { MaxLengthTextEditor } from '../shared/InlineTextEditor';
import PlusIcon from '../_styles/images/Add.png';
import DeleteIcon from '../_styles/images/delete.png';
import CommonToast from '../shared/components/common-toast-message/CommonToast';
import _ from 'lodash';
import analyticsService from '../shared/analytics/analytics-service';

const DEFAULT_MESSAGE = `I'd like you to join our vanpool.`;
const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function InviteToVanpool(props) {
  const [message, setMessage] = useState("")
  const [emails, setEmails] = useState([])
  const [email, setEmail] = useState(null)
  const [showPlus, setShowPlus] = useState(false);
  const [valid, setValid] = useState(true);
  const [toastOpen, updateToastOpen] = useState(false);
  const [toastData, updateToastData] = useState({
      message: '',
      type: ''
  });
  const [invalidError, setInvalidError] = useState(true);

  const handleChange = (e) => {
    var email = e.target.value.replaceAll(" ","");
    var AtleastOneValid = isAtleastOneValid(email);
    if (email.length) {
      if(AtleastOneValid) {
        setShowPlus(true);
        setInvalidError(true);
        setValid(true)
      }else {
        setShowPlus(false);
        setValid(false);
        setInvalidError(false)
      }
      setEmail(email);
    } else {
      setValid(true);
      setInvalidError(true);
      setEmail("");
    }

  };

  const addEmail = () => {
    var newEmail = email.split(',');
    var allEmails = emails.concat(newEmail);
    var isDuplicate = checkDuplicateEmail(allEmails);
    if(isDuplicate ) {
      setValid(false);
      setInvalidError(true)
    }else {
      setValid(true);
      setInvalidError(false)
    }
    var validEmails = allEmails.filter((e) => VALID_EMAIL.test(e.replace(/\s/g, "")));
    var uniqueEmails = _.uniq(validEmails).join(',').split(',');
    
    setEmails(uniqueEmails);
    setEmail("");
    setShowPlus(false);
    setInvalidError(true)
  };

  function validateEmailList(raw) {
    var emails = raw.split(',')
    var valid = true;
    for (var i = 0; i < emails.length; i++) {
      if (emails[i] === "" || !VALID_EMAIL.test(emails[i].replace(/\s/g, ""))) {
        valid = false;
      }
    }
    return valid;
  };

  function isAtleastOneValid(email) {
    var emails = email.split(',');
    var isvalid =  emails.filter((e) => VALID_EMAIL.test(e.replace(/\s/g, "")));
    return isvalid.length ? true : false;
  };
  
  function checkDuplicateEmail(array) {
    const seenValues = new Set();
    for (let value of array) {
      if (seenValues.has(value)) {
        return true;
      }
      seenValues.add(value);
    }
    return false;
  }

  const deleteEmail = (email, index) => {
    setEmails(prevActions => (
      // Filter out the item with the matching index
      prevActions.filter((email, i) => i !== index)
    ));
  };

  const sendInvites = () => {
    var payload = {
      emailIds: emails,
      message: message
    }
    props.inviteUsers(payload);
    reset();
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": "send invitation"   
      }
  });
  }

  const reset = () => {
    setEmails([]);
    setMessage(DEFAULT_MESSAGE)
  }

  useEffect(() => {
    if (props.inviteRiderSuccess === false) {
        updateToastData({
            message: 'There was an error while sending out invitation(s) to join the vanpool. Please try again. ',
            type: 'error'
        });
        updateToastOpen(true);
    } else if (props.inviteRiderSuccess === true) {
        updateToastData({
            message: 'Invitation(s) to join the vanpool have been sent out successfully',
            type: 'success'
        });
        updateToastOpen(true);
    }
}, [props.inviteRiderSuccess])

  return (
    <div className="invite-vanpool-page">
      <div className="container">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt="go back" src={ArrowLeft} tabIndex={0} role='button' />
            </Link>
          </div>
          <div className="heading">Invite to Vanpool</div>
        </div>
        <div className="main" role="group">
            <div className='email-container'>

        
          <div className='content'>
            <p>Enter email addresses of others you would like to join this vanpool</p>
            <fieldset className='email-input'>
              <label htmlFor='invite-email'>Email Address*</label>
              <input className={`${valid ? '' : 'error'}`} id='invite-email' aria-label='Enter email addresses' value={email} onChange={(e) => handleChange(e)} />
              {showPlus && <img id="addicon" className='plus-icon' src={PlusIcon} alt="add" onClick={() => addEmail()} />}
              {
                !valid && invalidError &&
                <div className='error'>
                  Invalid/duplicate emails have been removed. Invitations will only be sent to valid email addresses.
                </div>
              }
              {
                !invalidError &&
                <div className='error'>
                Enter a valid email address. Invitations will only be sent to valid email addresses.
              </div>
              }
            </fieldset>

            <div className='email-lists'>
              {
                emails && emails.length > 0 && emails.map((email, index) => {
                  return (
                    <div className='email-box'>
                      <div className='email-content'>{email}</div>
                      <div className='delete-content'>
                        <img id="deleteicon" className='delete-icon' src={DeleteIcon} alt="delete" onClick={() => deleteEmail(email, index)} />
                      </div>
                    </div>
                  )
                })
              }

            </div>
            <div className='message-container'>
              <label>Custom Message</label>
              <MaxLengthTextEditor
                className="invite-message"
                maxLength={500}
                placeholder={DEFAULT_MESSAGE}
                rows={2}
                value={message}
                onChange={message => setMessage(message)}
              />
            </div>
          </div>
          </div>
        </div>
        <div className='sticky-footer'>
          {
            emails.length ? 
            <div className='acknowledge'>
               By sending the invite(s), you acknowledge that the respective recipient(s) have given you permission.
            </div>
            :
            null
          }
          <button className='button send-invitation-button'  disabled={!emails.length} onClick={() => sendInvites()}>send invitation</button>
        </div>
      </div>
      <CommonToast message={toastData.message} type={toastData.type} isOpen={toastOpen} closeToast={() => updateToastOpen(false)} timeOut={6000000} />
    </div>
  );
}


function mapStateToProps(state) {
  return {
    inviteRiderSuccess: state.roster.inviteRiderSuccess
  };
}

function mapDispatchToProps(dispatch) {
  return {
    inviteUsers(payload) {
      dispatch({
        type: actionTypes.ROSTER_INVITE_USERS_LOADING,
        data: payload
      });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteToVanpool);
