import { useEffect, useState } from "react";
import { connect } from "react-redux";
import analyticsService from "../../../shared/analytics/analytics-service";
import { getTimeObjectFromString } from "../../../join-commute/helpers/commonHelper";
import { shouldShowAddress } from "../../../profile/helper";
import * as actionTypes from '../../../redux/actionTypes';
import stopStart from '../../../_styles/images/stopStart.png';
import stopEnd from '../../../_styles/images/stopEnd.png';
import User from '../../../_styles/images/User.png';
import CancelledStop from '../../../_styles/images/new-icons/CancelledStop.png';
import { Pencil } from '../../../shared/icons';

const Alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

function StopsContainer(props) {
    const [route, updateRoute] = useState({});
    const [isToHome, showToHomeData] = useState(false);
    const [isToWork, showToWorkData] = useState(true);
    const [stops, setStops] = useState([]);

    useEffect(() => {
        if (route && route.outboundRoutePoints && route.outboundRoutePoints.length > 0) {
            getRoutes(0);
        }
    }, [route]);

    useEffect(() => {
        if (props && props.route) {
            updateRoute(props.route);
        }
    }, [props])

    const showStop = data => {
        if (data) {
            showToHomeData(true);
            showToWorkData(false);
            getRoutes(data);
        } else {
            showToWorkData(true);
            showToHomeData(false);
            getRoutes(data);
        }
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": data ? "to home" : "to work"
            }
        })
    };

    function getRoutes(data) {
        const routeDirection = !data ? "outboundRoutePoints" : "inboundRoutePoints";
        const routes = route && route[routeDirection];
        props.setDirections(routes);
        setStops(routes);
    };

    function getTime(time) {
        if (time) {
            const timeObj = getTimeObjectFromString(time);
            return `${timeObj.hour}:${timeObj.minutes} ${timeObj.day}`
        }
    }

    function getAddress(stop) {
        const addArray = stop.address.split(/,(.*)/s);
        if (shouldShowAddress(stop, props.participantId)) {
            return (
                <div className='address'>
                    <div className='first-address'>{addArray[0]}</div>
                    <div className='second-address'>{addArray[1]}</div>
                </div>

            )
        } else {
            return (
                <div className='address'>
                    <div className='first-address'>{"Private Address"}</div>
                </div>
            )
        }
    }

    function onTabKeyDown(data, e) {
        if (e.which === 13) {
            showStop(data);
        }
    }
    let increm = 0;
    return (
        <div className='collapsible-content-container stops-main-container'>
            <div className="header">
                {props.heading && <div className="heading">{props.heading}</div>}
                {props.editable && props.showPencil && <Pencil onClick={() => props.toggleEdit()} className="edit-icon" />}
            </div>
            <div className="stops">
                <div className="tabs">
                    <div
                        className={`tab ${isToWork ? 'active' : ''}`}
                        onClick={() => showStop(0)}
                        onKeyDown={(e) => onTabKeyDown(0, e)}
                        role="button"
                        aria-label={`To Work ${isToWork ? 'selected' : 'not selected'}`}
                        tabIndex={0}
                    >
                        To Work
                    </div>
                    <div
                        className={`tab ${isToHome ? 'active' : ''}`}
                        onClick={() => showStop(1)}
                        onKeyDown={(e) => onTabKeyDown(1, e)}
                        role="button"
                        aria-label={`To Home ${!isToWork ? 'selected' : 'not selected'}`}
                        tabIndex={0}
                    >
                        To Home
                    </div>
                </div>
                <div className="tab-container">
                    {
                        stops.map((s, i) => {
                            if (props.showEmptyStops) {
                                return (
                                    <div className='stops-container' key={i}>
                                        <div className='first-section'>
                                            <div className='left-section'>
                                                <div className='icon'>
                                                    {
                                                        (s.stopOrder !== 0 && s.stopOrder !== -1 && s.todaysParticipants.length === 0) ?
                                                            <img className='stopCancelled' src={CancelledStop} tabIndex={0} />
                                                            :
                                                            <div className='stopOrder'>{Alphabets[i]}</div>

                                                    }
                                                </div>
                                                {getAddress(s)}
                                            </div>
                                            <div className='right-section'>
                                                <div className='time'>
                                                    {
                                                        s.stopOrder == 0 ? <div className='label'>{"Pickup"}</div>
                                                            : s.stopOrder == -1 ? <div className='label'>{"Dropoff"}</div>
                                                                : null
                                                    }

                                                    <div className='value'>{getTime(s.stopTime)}</div>
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            (isToWork && s.stopOrder !== -1) || (isToHome && s.stopOrder !== 0) ?
                                                (
                                                    s.todaysParticipants.length ?
                                                        <div className='second-section'>
                                                            <span className='icon'><img alt="stop start" src={User} tabIndex={0} /></span>
                                                            <span className='count'>{s.todaysParticipants.length}</span>
                                                        </div>
                                                        :
                                                        <div className='second-section disabled'>
                                                            <span className='count disabled'>No Riders</span>

                                                        </div>
                                                )
                                                :
                                                null
                                        }



                                    </div>
                                )
                            } else {
                                return (
                                    !s.todaysParticipants.length && !(s.stopOrder == -1 || s.stopOrder == 0) ?
                                        null
                                        :
                                        <div className='stops-container' key={i}>
                                            <div className='first-section'>
                                                <div className='left-section'>
                                                    <div className='icon'>
                                                        {
                                                            <div className='stopOrder'>{Alphabets[increm++]}</div>
                                                        }
                                                    </div>
                                                    {getAddress(s)}
                                                </div>
                                                <div className='right-section'>
                                                    <div className='time'>
                                                        {
                                                            s.stopOrder == 0 ? <div className='label'>{"Pickup"}</div>
                                                                : s.stopOrder == -1 ? <div className='label'>{"Dropoff"}</div>
                                                                    : null
                                                        }

                                                        <div className='value'>{getTime(s.stopTime)}</div>
                                                    </div>
                                                </div>

                                            </div>

                                            {
                                                (isToWork && s.stopOrder !== -1) || (isToHome && s.stopOrder !== 0) ?
                                                    <div className='second-section'>
                                                        <span className='icon'><img alt="stop start" src={User} tabIndex={0} /></span>
                                                        <span className='count'>{s.todaysParticipants.length}</span>
                                                    </div>
                                                    :
                                                    null
                                            }



                                        </div>
                                )
                            }
                        }
                        )
                    }


                </div>
            </div>
        </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setDirections(directions) {
            dispatch({ type: actionTypes.MAP_TODAYS_DIRECTIONS, data: directions });
            dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
        }
    };
}

function mapStateToProps(state) {
    return {
        participantId: state.userAuth.participantId,
        route: state.vanpoolRoute,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StopsContainer);