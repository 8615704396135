import React from 'react';
import Cell from '../../shared/Cell';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export default function RecurringSuccessConfirmation({ vanpool, payment }) {
  let selectedCard = payment.selectedCard;

  return (
    <div className="details">
      <div className="row">
        <Cell label="Type" value="Recurring Payment" />
        <Cell label="Vanpool ID" value={vanpool} />
      </div>
      <div className="row">
        <Cell size="full" label="Payment Name" value={payment.name} />
      </div>
      <div className="row">
        <Cell label="Payment Amount" value={'$' + numberWithCommas(payment.amount)} />
        <Cell label="First Scheduled Payment" value={selectedCard.next} />
      </div>
      <div className="row">
        <Cell
          size="full"
          label="Payment Method"
          value={`${selectedCard.brand} ${selectedCard.account} - EXP ${selectedCard.exp}`}
        />
      </div>
    </div>
  );
}
