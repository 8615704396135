/* eslint-disable no-undef */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MapService from '../shared/map-service';
import CancelledStop from '../_styles/images/new-icons/CancelledStop.png';

const controlsOff = {
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false
};

class RouteMap extends Component {
  constructor(props) {
    super(props);
    this.state = { ready: false, cancelledPoints: [] };
  }

  onLoad() {
    if (!this.state.ready && this.map) {
      this.setState({ ...this.state, ready: true });
      if (this.props.padding) {
        setTimeout(
          () =>
            this.map &&
            this.map.fitBounds(this.map.getBounds(), this.props.padding),
          200
        );
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.cancelledDirections && props.cancelledDirections.length > 0) {
      let cancelledStops = [];
      props.cancelledDirections.forEach(dir => {
        cancelledStops.push({
          "lat": dir.latitude,
          "lng": dir.longitude,
          "icon": CancelledStop
        })
      })
      return {...state, cancelledPoints: cancelledStops};
    }
    return {...state};
  }

  shouldComponentUpdate(props) {
    if (!this.state.ready) {
      return true;
    }

    if (!this.props.directions && !this.props.todaysDirections) {
      return true;
    } else if (this.props.directions) {
      if (
        this.props.directions.geocoded_waypoints && this.props.directions.geocoded_waypoints.length !==
        props.directions.geocoded_waypoints.length
      ) {
        return true;
      }

      return this.props.directions.geocoded_waypoints.some((waypoint, i) => {
        const newWaypoint = props.directions.geocoded_waypoints[i];
        return newWaypoint.place_id !== waypoint.place_id;
      });
    } else if (this.props.todaysDirections) {
      if (
        this.props.todaysDirections.geocoded_waypoints && this.props.todaysDirections.geocoded_waypoints.length !==
        props.todaysDirections.geocoded_waypoints.length
      ) {
        return true;
      }

      return this.props.todaysDirections.geocoded_waypoints.some((waypoint, i) => {
        const newWaypoint = props.todaysDirections.geocoded_waypoints[i];
        return newWaypoint.place_id !== waypoint.place_id;
      });
    }
  }

  render() {
    const { directions, controls = true, isToday = false, todaysDirections, isCustomMarkers } = this.props;
    const { ready, cancelledPoints } = this.state;
    var markers = [
      ...cancelledPoints
    ]
    return (
      <div className={`google-map ${ready ? 'ready' : ''}`}>
            <MapService.MapWithDirections
              directions={isToday ? todaysDirections : directions}
              options={!controls ? controlsOff : null}
              mapRef={map => (this.map = map)}
              onTilesLoaded={() => this.onLoad()}
              hideTitle={true}
            />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    directions: state.map.googleDirections,
    todaysDirections: state.map.todaysGoogleDirections,
    cancelledDirections: state.map.cancelledDirections
  };
}

export default connect(mapStateToProps)(RouteMap);
