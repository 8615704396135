import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isIE } from "react-device-detect";
import * as actionTypes from '../redux/actionTypes';
import { FeatureToggle } from '../shared/feature-toggles';
import RouteDays from './route-days/RouteDays';
import RouteDisplay from './route-stops/RouteDisplay';
import RouteMap from '../map/RouteMap';
import { Fuel, Clock, Dollar } from '../shared/icons';
import DateUtility from '../shared/dateUtility';
import currencyFormatter from '../shared/currencyFormatter';
import Loading from '../shared/loading/Loading';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_COORDINATOR
} from '../shared/permissions/permissions';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import IE11Warning from '../dashboard/IE11Warning';
import { numberWithCommas } from '../join-commute/helpers/commonHelper';
import analyticsService from '../shared/analytics/analytics-service';
import RosterContainer from '../vanpool/components/rosterContainer';
import StopsContainer from '../vanpool/components/route/StopsContainer';
import StopsEditor from './route-stops/StopsEditor';

class RoutePresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      showRoute: 'outboundRoutePoints',
      saving: false,
      promptSwitch: false,
      switchTabs: false
    };
  }

  componentDidMount() {
    this.props.setDirections([]);
    if (!this.props.rosterLoaded) {
      this.setState({ saving: true })
      this.props.init();
    }
  }

  componentDidUpdate() {
    if (this.props.loadError) {
      if (this.props.loadErrorString && this.props.loadErrorString.message)
        analyticsService.analyticsProcessEvent(
          {
            "event": "api_error",
            "context": {
              "event_action": this.props.loadErrorString.message
            }
          });
      this.props.history.replace('/error');
    }
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    if (
      nextProps.route.runDays.length &&
      (this.state.saving || this.props.route !== nextProps.route)
    ) {
      this.setState({
        saving: false,
        promptSwitch: this.state.willPrompt,
        willPrompt: false
      });
      this.props.setDirections(nextProps.route[this.state.showRoute]);
    }

    if (nextProps.route.error) {
      this.setState({ saving: false })
    }
  }

  switchAndEdit() {
    this.setState(
      {
        promptSwitch: false,
        showRoute:
          this.state.showRoute === 'outboundRoutePoints'
            ? 'inboundRoutePoints'
            : 'outboundRoutePoints'
      },
      () => this.display.toggleMyEdit()
    );
  }

  update(field, value, willPrompt) {
    this.setState({ saving: true, promptSwitch: false, willPrompt });
    this.props.save({ ...this.props.route, [field]: value });
  }

  toggleEdit() {
    this.setState({ editing: !this.state.editing });
    this.props.setDirections(this.props.route[this.state.showRoute]);
  }

  showRoute(showRoute) {
    this.setState({ showRoute });
    this.props.setDirections(this.props.route[showRoute]);
  }

  render() {
    const {
      runDays,
      participantRideDays,
      estimatedFuelSavingsGallons,
      estimatedFuelSavingsDollars
    } = this.props.route;
    const isToWork = this.state.showRoute === 'outboundRoutePoints';
    const isToHome = this.state.showRoute === 'inboundRoutePoints';
    const duration = isToWork
      ? this.props.route.outboundRouteDuration
      : this.props.route.inboundRouteDuration;
    const routeToShow = this.props.route[this.state.showRoute];
    const canEdit =
    isAllowed([
      PERMISSION_TRIP_RECORDING,
      PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
      PERMISSION_COORDINATOR
    ]) && !this.state.editing;
    const promptSwitch = this.state.promptSwitch;

    const saveStops = (stops) => {
      this.update(this.state.showRoute, stops, true);
      this.toggleEdit();
    }

    return (
      <FeatureToggle name="vanpoolRoute">
        <ConfirmationPopup
          active={!!promptSwitch}
          title="Route Updated"
          message={
            'Your route has been updated. However, changes will NOT be applied to the opposite route unless you make the changes. Would you like to update the opposite route?'
          }
          confirmMessage={'Yes'}
          confirm={() => this.switchAndEdit()}
          cancelMessage={'No'}
          cancel={() => this.setState({ promptSwitch: false })}
        />
        <div className='vanpool-route-container'>
          <div className="vanpool-route">
            <div className="vanpool-route-details">
              <Loading isLoading={this.state.saving} absolute={true} />
              {
                this.props.profile.isSubscription ?
                  <>
                    <RosterContainer heading="Today's Roster" participants={this.props.participants} history={this.props.history} slice={5} />
                    <StopsContainer heading="Today's Stops" showEmptyStops={true} customIcons={false} showPencil={false}/>
                  </>
                  :
                  <>
                   <RosterContainer heading="Today's Roster" participants={this.props.participants} history={this.props.history} slice={5} />
                    <RouteDays
                      days={runDays}
                      participantDays={participantRideDays}
                      toggleEdit={() => this.toggleEdit()}
                      editable={canEdit}
                      onChange={newDays => this.update('runDays', newDays)}
                      isSubscription={this.props.profile.isSubscription}
                    />
                    {
                      this.state.editing ?
                        <StopsEditor
                          stops={JSON.parse(JSON.stringify(routeToShow))}
                          route={isToWork ? 'Work' : 'Home'}
                          save={stops => saveStops(stops)}
                          cancel={() => this.toggleEdit()}
                        />
                        :
                        <StopsContainer heading="Today's Stops" showEmptyStops={true} customIcons={false} showPencil={true} editable={canEdit} toggleEdit={() => this.toggleEdit()} />
                    }
                  </>
              }
              {this.state.editing ? null : (
                <div className="analytics">
                  <ul>
                    <li>
                      <span>
                        <Clock /> Route Time
                      </span>{' '}
                      <span id="route-duration">
                        {DateUtility.getHoursAndMinutes(duration)}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Dollar /> Average Savings
                      </span>{' '}
                      <span id="dollars-savings">
                        {numberWithCommas(currencyFormatter.formatUSD(estimatedFuelSavingsDollars))}
                      </span>
                    </li>
                    <li>
                      <span>
                        <Fuel /> Average Fuel Saved
                      </span>{' '}
                      <span id="gallons-savings">
                        {numberWithCommas(estimatedFuelSavingsGallons)} gal
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {isIE ?
              <IE11Warning />
              :
              <RouteMap isToday={false} isCustomMarkers={true} />
            }


          </div>
        </div>
      </FeatureToggle>
    );
  }
}

function mapStateToProps(state) {
  return {
    route: state.vanpoolRoute,
    loadError: state.vanpoolRoute.loadError,
    loadErrorString: state.vanpoolRoute.loadErrorString,
    routeLoaded: state.vanpoolRoute.loaded,
    profile: state.userAuth,
    participantId: state.userAuth.participantId,
    participants: state.roster.rosterParticipants,
    rosterLoaded: state.roster.loaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROSTER_LOADING });
      dispatch({ type: actionTypes.ROUTE_LOADING });
    },
    save(route) {
      dispatch({ type: actionTypes.ROUTE_SAVING, data: route });
    },
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_TODAYS_DIRECTIONS, data: directions });
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoutePresentation);
