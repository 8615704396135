import { useEffect, useRef, useState } from 'react';
import ArrowLeft from '../../../../_styles/images/ArrowLeft.png';
import Van from '../../../../_styles/images/SUV.svg';
import { Link } from 'react-router-dom';
import DollarSign from '../../../../_styles/images/new-icons/DollarSign.svg'
import SelectDays from '../../change-days/select-days/SelectDays';
import { connect } from 'react-redux'; import { getDaysFromPackage } from '../../../../join-commute/helpers/vanHelpers';
import * as actionTypes from '../../../../redux/actionTypes';
import DiscardChanges from '../../common/discardChangesPopup/DiscardChanges';
import { isEqual } from 'lodash';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { getDaysForAnalytics } from '../../../../shared/analytics/analytics-helpers';


function UpgradeSection(props) {

  const navigationAlertNode = useRef(null);

  const [selectedDays, updateSelectedDays] = useState([]);
  const [vanpool, updateVanpool] = useState(false);
  const [plan, updatePlan] = useState('');
  const [disabled, updateDisabled] = useState(false);

  useEffect(() => {
    if (!props.selectedPlan) {
      props.history.goBack();
    } else {
      const days = getDaysFromPackage(props.selectedPlan.packageName);
      updatePlan(days);
    }
  }, [props.selectedPlan])

  useEffect(() => {
    if (props.vanpoolInfo) {
      updateVanpool(props.vanpoolInfo);
      if (props.vanpoolInfo.commuteDays) {
        updateSelectedDays(props.vanpoolInfo.commuteDays);
      }
    }
  }, [props.vanpoolInfo])

  useEffect(() => {
    if ((selectedDays.length === 0) || (plan !== 5 && selectedDays.length > plan)) {
      updateDisabled(true);
    } else {
      updateDisabled(false);
    }
  }, [selectedDays])

  function isCommuteDaysChanged() {
    if (props.vanpoolInfo && props.vanpoolInfo.commuteDays && selectedDays) {
      return !isEqual(props.vanpoolInfo.commuteDays, selectedDays);
    }
    return false;
  }

  useEffect(() => {
    analyticsService.analyticsProcessEvent({
      "event": "view_upgraded",
      "context": {
        "event_action": (props.selectedPlan && props.selectedPlan.isPlanUpgrade) ? `upgraded to: ${props.selectedPlan.analyticsPackageName}!` : `downgraded to: ${props.selectedPlan.analyticsPackageName}!`
      }
    });
    if (props.selectedPlan && props.selectedPlan.days) {
      updateSelectedDays(props.selectedPlan.days);
    }
  }, [])

  function checkNextStep() {
    const analyticsSelectedDays = getDaysForAnalytics(selectedDays);
    analyticsService.analyticsProcessEvent({
      "event": "change_subscription_upgrade_continue",
      "context": {
        "event_action": 'continue',
        "event_detail": {
          "days_selected": `${analyticsSelectedDays}:${props.selectedPlan.analyticsPackageName}!`
        }
      }
    });
    props.saveSelectedPlan({ ...props.selectedPlan, days: selectedDays })
    if (props.selectedPlan.isRoleChange && props.selectedPlan.role === 'driver') {
      props.history.push("/myprofile/change/plan/licenseNotification");
    } else {
      props.getDocusignContract(false, props.vanpoolInfo.id, props.history, '/myprofile/change/plan/summary');
    }
  }

  return (
    <div className="change-subscription-container">
      <div className="change-subscription-page upgrade-section">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt="go back" src={ArrowLeft}></img>
            </Link>
          </div>
          <div className="heading">Change Subscription Plan</div>
        </div>
        <div className="container">
          <div className='upgrade-content'>
            <div className='left-section'>
              {props.selectedPlan && <h2 className={props.selectedPlan && props.selectedPlan.isPlanUpgrade ? 'header-text big' : 'header-text small'}>{props.selectedPlan.upgradeText}</h2>}
              <div className='comment-text'>These are the days your vanpool runs. Save your seat in the van by selecting the days you’d like to ride.</div>
              <div className='selected-days-badge' role='group'><span className='selected-days-label'>Days Selected :</span><span className='selected-days-value'>&nbsp;{selectedDays.length}</span></div>
              <div className="commuteDays">
                <SelectDays
                  runningDays={vanpool.operationalDays}
                  value={selectedDays}
                  onlyShowRunningDays={true}
                  onChange={updateSelectedDays}
                  minDaysError={!!(selectedDays.length === 0)}
                  availableDays={vanpool.vanAvailableDays}
                  alreadySelected={vanpool.commuteDays}
                  maxDaysError={plan === 5 ? null : selectedDays.length > plan}
                  maxDays={plan}
                />
              </div>
              {(plan !== 5 && selectedDays.length > plan) && <div className='foot-note' role='alert'>
                If you wish to add more days you will need to change your subscription and/or find a new vanpool.
              </div>}
              <div className='notification'>
                <img src={DollarSign} aria-hidden='true'></img>
                <span>Your new Subscription rate will take effect next month. Prorated amounts owed for the upgraded plan for this month will be added to your next month's invoice.</span>
              </div>
            </div>
            <div className='right-section'>
              <img src={Van} alt='van image'></img>
            </div>
          </div>
          <div className="buttons">
            <button className='cancel-button' onClick={props.history.goBack}>cancel</button>
            <DiscardChanges
              heading='Unsaved Changes'
              text='You’ll lose any unsaved changes if you leave now. Do you want to continue editing?'
              cancelButtonText='continue editing'
              confirmButtonText='leave'
              when={true}
              navigate={path => props.history.replace(path)}
              shouldBlockNavigation={location => {
                if (isCommuteDaysChanged()) {
                  if (location.pathname === '/myprofile/change/plan/upgrade' || location.pathname === '/myprofile/change/plan/summary' || location.pathname === '/myprofile' || location.pathname === '/agreement' || location.pathname === '/myprofile/change/plan/licenseNotification') {
                    return false
                  }
                  return true;
                }
                return false;
              }}
              handleValidation={() => { }}
              reference={navigationAlertNode}
            />
            <button className='continue-button' disabled={disabled} onClick={checkNextStep}>Continue</button>
          </div>
        </div>
      </div>

    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedPlan: state.profile.selectedPlan,
    vanpoolInfo: state.profile.vanpool,
    loaded: state.profile.isLoaded,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSelectedPlan(selectedPackage) {
      dispatch({ type: actionTypes.SET_SELECTED_PLAN, data: selectedPackage });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeSection);