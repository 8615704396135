import React, { Component } from 'react';
import { Stopwatch } from '../../shared/icons';
import FieldInfo from '../../shared/FieldInfo';


function DailyTripTimeInfo() {
  return (
    <div>
      <p>Daily Trip Details is a required field for Trip Recording that should have accurate drive times and mileage for each day</p>
    </div>
  );
}
export default function() {
    return (
      <div className="driveTimeDuration" role='group'>
        <Stopwatch className="stopwatch" aria-label='daily trip icon'/>
      <div className="driveTimeHeading" aria-label='Daily Trip Details'>Daily Trip Details</div>
      <FieldInfo  aria-label='info icon' message={<DailyTripTimeInfo />} />

      </div>
    );
}