import { Link } from 'react-router-dom';
import ArrowLeft from '../../../_styles/images/ArrowLeft.png';
import Loading from '../../../shared/loading/Loading';
import Dropdown from '../../../join-commute/payment-screen/dropdown/Dropdown';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../../redux/actionTypes';
import { addDays, getFullDate } from '../../../join-commute/helpers/commonHelper';
import { RadioButton } from '../common/radio-buttons/RadioButton';
import DiscardChanges from '../common/discardChangesPopup/DiscardChanges';
import { isEmpty } from 'lodash';
import { useRef } from 'react';
import Tooltip from '../../../shared/Tooltip';
import AddLicenseCard from './add-license-card/AddLicenseCard';
import { getDateObjFromString, getDateString } from '../../../payment-history/helper';
import ContactPopup from '../../../shared/components/contact-popup/ContactPopup';
import { getAgeFromDOB } from '../../../shared/helpers/dateHelpers';
import Callable from '../../../shared/Callable';
import DateInput from '../../../shared/inputs/DateInput';
import analyticsService from '../../../shared/analytics/analytics-service';
import useError from '../../../shared/components/error-screen/useError';
import dateUtility from '../../../shared/dateUtility';
import StateDropdown from '../../../join-commute/payment-screen/dropdown/state-dropdown/StateDropdown';

const initLicenseData = {
  licenseNumber: '',
  licenseState: ''
}

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function ApplyToDriveEdit(props) {

  const ageError = 'All drivers must be 25 years or older (21 years or older in Michigan or New York). For questions, please call 800 VAN 4 WORK.';

  const navigationAlertNode = useRef();
  const DBPopupNode = useRef();
  const DLPopupNode = useRef();

  const [birthDate, updateBirthDate] = useState();
  const [birthDateString, updateBirthDateString] = useState('');
  const [firstName, updateFirstName] = useState('');
  const [lastName, updateLastName] = useState('');
  const [licenseNumber, updateLicenseNumber] = useState('');
  const [licenseState, updateLicenseState] = useState('');
  const [continueDisabled, updateContinueDisabled] = useState(true);
  const [continueAsRiderDisabled, updateContinueAsRiderDisabled] = useState(true);
  const [firstOption, updateFirstOption] = useState(0);
  const [secondOption, updateSecondOption] = useState(0);
  const [isDataChanged, updateIsDataChanged] = useState(false);
  const [showDBPopup, updateShowDBPopup] = useState(false);
  const [showDLPopup, updateShowDLPopup] = useState(false);
  const [showAddLicenseContainer, updateShowAddLicenseContainer] = useState(false);
  const [licenses, updateLicenses] = useState([]);
  const [hasPrevLicChecked, updateHasPrevLicChecked] = useState(false);
  const [isLoading, updateIsLoading] = useState(false);
  const [isAgeError, updateIsAgeError] = useState(false);
  const [isToBeRider, updateIsToBeRider] = useState(false);
  const [isDateValid, updateIsDateValid] = useState(true);

  useOnClickOutside(DBPopupNode, () => updateShowDBPopup(false));
  useOnClickOutside(DLPopupNode, () => updateShowDLPopup(false));

  useError(props.statesLoadingError, props.history, props.statesLoadingErrorString)
  useEffect(() => {
    if (!props.isLoaded) {
      updateIsLoading(true);
      props.init();
    }
    if (!props.statesLoaded) {
      updateIsLoading(true);
      props.loadStates();
    }
    checkAndUpdateDataFromStore();
    checkValidationAndEnableContinue();
  }, [])

  useEffect(() => {
    if (props.isLoaded && props.statesLoaded) {
      updateIsLoading(false);
    }
  }, [props.isLoaded, props.statesLoaded])

  useEffect(() => {
    if (birthDateString !== '') {
      if (birthDateString.length === 10) {
        const date = new Date(birthDateString);
        updateBirthDate(date);
        const age = getAgeFromDOB(date);
        if (age <= 24) {
          updateIsAgeError(true);
        } else {
          updateIsAgeError(false);
        }
        const day = getFullDate(date);
        updateBirthDate(day);
      } else {
        updateIsAgeError(false);
        updateIsDateValid(false);
      }
    } else {
      updateIsDateValid(false);
    }

  }, [birthDateString])

  function onLicenseChanged(val) {
    if (/^[a-zA-Z0-9]*$/.test(val) && val.length <= 19) {
      updateLicenseNumber(val);
    }
  }

  function onNameChange(val, type) {
    if (type === 'fname') {
      if (/^[A-Za-z]*$/.test(val)) {
        updateFirstName(val);
      }
    }
    if (type === 'lname') {
      if (/^[A-Za-z]*$/.test(val)) {
        updateLastName(val);
      }
    }
  }

  function checkAdditionalLicensesData() {
    const isValidArray = licenses.map((license) => {
      if (license.licenseNumber === '' || license.licenseState === '') {
        return false;
      } else {
        return true;
      }
    });
    const isValid = !isValidArray.includes(false);
    return isValid;
  }

  function checkValidationAndEnableContinue() {
    const changedData = checkDataChanged();
    updateIsDataChanged(changedData);
    const licenseDataValid = hasPrevLicChecked ? true : checkAdditionalLicensesData();
    const checkValid = isDateValid || !(birthDateString == '' || birthDateString.length !== 10);
    if (firstName !== '' && lastName !== '' && licenseNumber !== '' && licenseState !== '' && birthDate && firstOption && secondOption && licenseDataValid && !isAgeError && checkValid) {
      updateContinueDisabled(false);
    } else {
      updateContinueDisabled(true);
    }
    if (firstName !== '' && lastName !== '' && licenseNumber !== '' && licenseState !== '' && birthDate && firstOption && secondOption && (licenseDataValid || isAgeError || !checkValid)) {
      updateContinueAsRiderDisabled(false);
    } else {
      updateContinueAsRiderDisabled(true);
    }
  }

  useEffect(() => {
    checkValidationAndEnableContinue();
  }, [firstName, lastName, licenseNumber, licenseState, licenses, birthDate , firstOption, secondOption, hasPrevLicChecked, isAgeError, isDateValid, birthDateString])

  function checkDataChanged() {
    const isDataChanged = (firstName !== '' || lastName !== '' || licenseNumber !== '' || licenseState !== '' || !isEmpty(birthDate) || firstOption || secondOption);
    return isDataChanged;
  }

  function licenseOptionClicked(option) {
    updateFirstOption(option);
    if (option === 2) {
      updateShowAddLicenseContainer(true);
      updateLicenses([initLicenseData])
    } else {
      updateShowAddLicenseContainer(false);
      updateLicenses([])

    }
  }

  const addLicense = () => {
    if (licenses.length <= 1) {
      let newLicenses = [...licenses, initLicenseData];
      updateLicenses(newLicenses);
    }
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": "add another U.S. license"
      }
  });
  }

  function removeLastLicense() {
    updateLicenses([licenses[0]]);
  }

  function changeLicense(type, val, index) {
    let newLicenses = [...licenses];
    if (type == 'licenseNumber') {
      if (/^[a-zA-Z0-9]*$/.test(val) && val.length <= 19) {
        newLicenses[index] = {
          licenseNumber: val,
          licenseState: newLicenses[index].licenseState
        };
      }
    }
    if (type == 'licenseState') {
      newLicenses[index] = {
        licenseNumber: newLicenses[index].licenseNumber,
        licenseState: val
      }
    }
    updateLicenses(newLicenses);
  }

  function navigateToProfileOverview() {
    return props.history.goBack();
  }

  function riderSelected() {
    updateIsDataChanged(false);
    props.clearApplicationData();
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": "continue"
      }
  })
    if (props && props.flow) {
      if (props.flow === 'findAVan') {
        props.setUserDrivingSchedule({
          isDesiredDriverRole: false,
          isChangedPlan: true,
          isFrequentDriver: null,
          role: 2
        });
        if (props.joinCommuteData && props.joinCommuteData.isCoordinatorInvite) {
          props.history.push('/JoinCommute/apply/coordinator');
        } else {
          const vanpoolId = props.selectedVan.vanpoolId ? props.selectedVan.vanpoolId : props.userInfo.vanpoolId
          // props.getDocusignContract(false, vanpoolId, props.history, props.navigateTo);
          props.history.push('/JoinCommute/verify/home-address');
        }
      }
      else if (props.flow === 'profile') {
        navigateToProfileOverview();
      }
    }
  }

  function checkAndUpdateDataFromStore() {
    if (props.driverApplicationData && props.driverApplicationData.apiData && props.driverApplicationData.secondaryData) {
      const appData = props.driverApplicationData.apiData.driverCoordInformation;
      const secondaryData = props.driverApplicationData.secondaryData;
      updateFirstName(appData.firstName);
      updateLastName(appData.lastName);
      const dateObj = getDateObjFromString(appData.dateOfBirth);
      if (dateObj && dateObj.date) {
        updateBirthDate(dateObj.date);
        const dateValue = dateUtility.formatexpenses(dateObj.date);
        updateBirthDateString(dateValue);
      }
      updateLicenseNumber(appData.licenses[0].licenseNumber);
      updateLicenseState(appData.licenses[0].licenseState);
      updateLicenses(appData.licenses.slice(1));
      updateFirstOption(secondaryData.firstOption);
      if (secondaryData.firstOption === 2) {
        updateShowAddLicenseContainer(true);
      }
      updateSecondOption(secondaryData.secondOption);
      updateHasPrevLicChecked(secondaryData.hasPrevLicChecked);
    }
  }

  useEffect(() => {
    checkAndUpdateDataFromStore();
  }, [props.driverApplicationData])

  function getEffectiveDate() {
    const futureDate = addDays(30, new Date());
    const fullDate = getFullDate(futureDate);
    const dateString = getDateString(fullDate);
    return dateString;
  }

  const setApplicationData = () => {
    const phone = props && props.userInfo && props.userInfo.phone;
    if (phone) {
      phone.isPreferred = true;
    }
    const driverCoordInformation = {
      callbackURL: 'https://commutewithenterprise.com/completeenvelope',
      dateOfBirth: getDateString(birthDate),
      firstName,
      lastName,
      homeAddress: props.findAVan ? null : props.profile.homeAddress,
      isCoordinatorApplication: false,
      isActiveMilitary: secondOption === 1 ? true : false,
      isDriverApplication: true,
      isForeignLicense: false,
      licenses: [{ licenseNumber, licenseState, isLatestLicense: true }, ...licenses],
      phones: (props.profile.phones && props.profile.phones.length > 0) ? props.profile.phones : phone ? [phone] : [],
      yearsAtHomeAddress: 10,
    }
    const data = {
      driverCoordInformation,
      packageDiscountType: "Driver",
      packageName: props.profile && props.profile.subscription && props.profile.subscription.package,
      rosterDays: props.profile && props.profile.rosterDays,
      subscriptionType: "Fixed"
    };
    if(props?.flow !== 'profile'){
      data.effectiveDate = getEffectiveDate()
    }
    const secondaryData = {
      firstOption,
      secondOption,
      hasPrevLicChecked,
    }
    props.setApplicationData(data, secondaryData);
    var vanpoolId = props.selectedVan && props.selectedVan.vanpoolId ? props.selectedVan.vanpoolId : props.userInfo.vanpoolId;
    if (props.flow == 'findAVan') {
      if (props.joinCommuteData && props.joinCommuteData.isCoordinatorInvite) {
        props.history.push('/JoinCommute/apply/coordinator');
      } else {
        // props.getDocusignContract(true, vanpoolId, props.history, props.navigateTo);
        props.history.push('/JoinCommute/verify/home-address');
      }
    } else {
      props.getDocusignContract(true, vanpoolId, props.history, props.navigateTo);
    }
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        const popupElement = document.getElementById('one-time-charges-popup');
        if (popupElement) {
          updateShowDBPopup(false);
          updateShowDLPopup(false);
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, []);

  useEffect(() => {
    if (hasPrevLicChecked || isAgeError) {
      updateIsToBeRider(true);
    } else {
      updateIsToBeRider(false);
    }
  }, [hasPrevLicChecked, isAgeError])

  function updateDate(val, valid) {
    updateBirthDateString(val);
    updateIsDateValid(valid);
  }

  function sendAnalytics() {
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
          "event_action": "all drivers must be 25 modal"
      }
  });
  }

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={isLoading} />
      <div className='profile-edit-page edit-license-page'>
        <div className='view-container' role='group'>
          <div className='header'>
            <div className='backArrow'>
              <div className="overview-link" onClick={props.history.goBack}>
                <img alt='go back' src={ArrowLeft}></img>
              </div>
            </div>
            <div className='heading'>Driver’s License</div>
          </div>
          <div className='content-container' role='group'>
            <div className='small-heading'>Drivers are required to have five years of driving experience in the U.S.</div>
            <div className='name'>
              <fieldset className='field'>
                <div className='label'>
                  <label>First Name On Driver’s License*</label>
                </div>
                <input required placeholder='First name' value={firstName} onChange={(e) => onNameChange(e.target.value, 'fname')}></input>
              </fieldset>
              <fieldset className='field'>
                <div className='label'>
                  <label>Last Name On Driver’s License*</label>
                </div>
                <input required placeholder='Last name' value={lastName} onChange={(e) => onNameChange(e.target.value, 'lname')}></input>
              </fieldset>
            </div>
            <fieldset className='field'>
              <div className='label spread license'>
                <label>Driver’s License Number*</label>
                <Tooltip tooltipLabel='Driver’s License Number' onClick={() => updateShowDLPopup(true)} />
              </div>
              <input placeholder='Driver’s license number' value={licenseNumber} onChange={(e) => onLicenseChanged(e.target.value)}></input>
            </fieldset>
            <div className='state-date'>
              <div className='field'>
                <StateDropdown
                  label="State Issued*"
                  placeholder='State'
                  onChange={(val) => updateLicenseState(val)}
                  options={props.states}
                  value={licenseState}
                />
              </div>
              <div className='field'>
                <fieldset className='licence-picker-container'>
                  <div className='label spread'>
                    <label>Date of Birth*</label>
                    <Tooltip tooltipLabel='Date of Birth' onClick={() => { sendAnalytics; updateShowDBPopup(true)}} />
                  </div>
                  <DateInput
                    value={birthDateString}
                    aria-invalid={isAgeError} isError={isAgeError}
                    onChange={(date) => updateDate(date.value, date.valid)}
                    required
                  />
                </fieldset>
              </div>
              {isAgeError && <div className='error-message cvv-error'>
                <span role="alert" aria-errormessage={ageError}>All drivers must be 25 years or older (21 years or older in Michigan or New York). For questions, please call <Callable phone="800 VAN 4 WORK" /></span>
              </div>}
            </div>
            <div className='questions-section'>
              <div className='question-text'>Are any of the following statements true?</div>
              <fieldset className='question' role='radiogroup' aria-labelledby='question1' tabIndex={0}>
                <label className='question-text' id='question1'>
                  Have you had this license for more than 5 years?
                </label>
                <RadioButton value={firstOption} onChange={licenseOptionClicked} />
              </fieldset>
              {showAddLicenseContainer &&
                <AddLicenseCard
                  isChecked={hasPrevLicChecked}
                  updateIsChecked={updateHasPrevLicChecked}
                  licenses={licenses}
                  updateLicenses={updateLicenses}
                  addLicense={addLicense}
                  changeLicense={changeLicense}
                  removeLastLicense={removeLastLicense}
                  states={props.states} />}
              <fieldset className='question' role='radiogroup' aria-labelledby='question2' tabIndex={0}>
                <label id='question2' className='question-text'>
                  I am currently on active duty in the military
                </label>
                <RadioButton value={secondOption} onChange={updateSecondOption} />
              </fieldset>
            </div>
          </div>
          <div className='buttons'>
            {isToBeRider ?
              <button className='save' disabled={continueAsRiderDisabled} onClick={riderSelected}>Continue as a rider</button> :
              <button className='save' disabled={continueDisabled} onClick={setApplicationData}>Continue</button>}
          </div>
        </div>
        <DiscardChanges
          heading='Unsaved Changes'
          text='You’ll lose any unsaved changes if you leave now. Do you want to continue editing?'
          cancelButtonText='continue editing'
          confirmButtonText='leave'
          when={isDataChanged}
          navigate={path => props.history.replace(path)}
          shouldBlockNavigation={location => {
            if (isDataChanged) {
              if (location.pathname !== props.presentRoute && location.pathname !== props.navigateTo && !(location.pathname === props.goBack && hasPrevLicChecked)) {
                if (location.pathname == "/agreement" || location.pathname == "/JoinCommute/apply/coordinator" || location.pathname == "/JoinCommute/verify/home-address") {
                  return false
                }
                if (props.flow !== 'findAVan') {
                  analyticsService.getPageName({
                    'pagename': "profile/manage subscription/drivers license/unsaved changes modal"
                  });
                }
                return true;
              }
            }
          }}
          handleValidation={() => {
            checkDataChanged();
          }}
          reference={navigationAlertNode}
        />
        <span aria-live='polite' role='alert'>
          {showDLPopup && <ContactPopup firstText='As part of the Commute with Enterprise driver application process, each driver’s MVR (Motor Vehicle Record) is checked annually to ensure that it is in compliance with our policies. Only approved drivers may operate the vehicles.' reference={DLPopupNode} close={() => updateShowDLPopup(false)} />}
          {showDBPopup && <ContactPopup title='All drivers must be 25 years or older.' firstText='All drivers must be 25 years or older (21 years or older in Michigan or New York) and meet minimum driver requirements.' contactText='For questions, please call' reference={DBPopupNode} close={() => updateShowDBPopup(false)} />}
        </span>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    states: state.profile.states,
    profile: state.profile,
    isLoaded: state.profile.isLoaded,
    driverApplicationData: state.profile.driverApplicationData,
    joinCommuteData: state.joinCommute,
    isDesiredDriverRole: state.joinCommute.drivingScheduleData.isDesiredDriverRole,
    startAddress: state.joinCommute.userCommuteData.homeAddressExpanded,
    selectedPlan: state.profile.selectedPlan,
    selectedVan: state.joinCommute.selectedVan,
    userInfo: state.userAuth,
    statesLoaded: state.profile.statesLoaded,
    statesLoadingError: state.profile.statesLoadingError,
    statesLoadingErrorString: state.profile.statesLoadingErrorString,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    setApplicationData(apiData, secondaryData) {
      dispatch({ type: actionTypes.SET_APPLY_TO_DRIVE_DATA, data: apiData, secondaryData });
    },
    clearApplicationData() {
      dispatch({ type: actionTypes.CLEAR_APPLY_TO_DRIVE_DATA });
    },
    setUserDrivingSchedule(data) {
      dispatch({ type: actionTypes.SET_USER_DRIVING_SCHEDULE, data });
    },
    saveSelectedPlan(selectedPackage) {
      dispatch({ type: actionTypes.SET_SELECTED_PLAN, data: selectedPackage });
    },
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
    loadStates() {
      dispatch({ type: actionTypes.STATES_LOADING })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToDriveEdit);