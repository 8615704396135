import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import Vanpoolers from './Vanpoolers';
import DriveTimeDuration from './DriveTimeDuration';
import dateUtility from '../../shared/dateUtility';
import FieldInfo from '../../shared/FieldInfo';
import DayStatus from './DayStatus';
import TimeStatus from './TimeStatus';
import WeekNavigation from './WeekNavigation';
import moment from 'moment';
import Loading from '../../shared/loading/Loading';
import Legend from './Legend';
import ImpactData from '../ImpactData';
import classList from '../../shared/classList';
import ConfirmationPopup from '../../shared/popup/ConfirmationPopup';
import { RestrictFrom } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';
import logger from '../../shared/logger';
import analyticsService from '../../shared/analytics/analytics-service';
import { handleErrorScreen } from '../../shared/components/error-screen/useError';

function HotKeyInfo() {
  return (
    <div>
      <p>Click to change or use the keyboard.</p>
      <p>
        'R' <span>- Roundtrip</span>
      </p>
      <p>
        'W' <span>- To Work</span>
      </p>
      <p>
        'H' <span>- To Home</span>
      </p>
      <p>
        'X' <span>- Did Not Commute</span>
      </p>
      <p>Space to rotate through options.</p>
    </div>
  );
}

function changeDay(days, date, user, toWork, toHome, isChanged, singleSelected, multiSelected) {
  return days.map(day => ({
    ...day,
    vanpoolers: day.vanpoolers.map((vanpooler, i) => ({
      ...vanpooler,
      toWork: date === day.date && user === i ? toWork : vanpooler.toWork,
      toHome: date === day.date && user === i ? toHome : vanpooler.toHome,
      isChanged:
        date === day.date && user === i ? isChanged : vanpooler.isChanged,
      singleSelected, 
      multiSelected
    }))
  }));
}

function changeTime(days, date, workStart, workEnd, homeStart, homeEnd, homeMiles, workMiles, isChanged) {
  return days.map(day => ({
    ...day,
    timedata : day.date == date ? {
      ...day.timedata,
      homeBoundEndTime : homeEnd,
      homeBoundStartTime : homeStart,
      workBoundEndTime : workEnd,
      workBoundStartTime : workStart,
      workBoundMiles: workMiles,
      homeBoundMiles: homeMiles,
      isChanged: isChanged
    }
    :
    {
      ...day.timedata,
      homeBoundEndTime : day.timedata.homeBoundEndTime,
      homeBoundStartTime : day.timedata.homeBoundStartTime,
      workBoundEndTime : day.timedata.workBoundEndTime,
      workBoundStartTime : day.timedata.workBoundStartTime,
      workBoundMiles: day.timedata.workBoundMiles,
      homeBoundMiles: day.timedata.homeBoundMiles,
      isChanged: day.timedata.isChanged
    }
  }));
}

export class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: props.month || 'current',
      monthDays: props.current ? props[props.month].days : [],
      monthTimeDays: props.current ? props[props.month].timedata : [],
      week: this.latestWeekOf(props.month || 'current'),
      days: [],
      tripTimeDays:[],
      before: [],
      after: [],
      selected: { date: '', index: -1 },
      selectedTimeDate: '',
      loading: false,
      requireExpenses: false,
      showAcknowledgement: false,
      acknowledged: false,
      currentMonthDays : [],
      previousMonthDays : []
    };

    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.openStatus = this.openStatus.bind(this);
    this.openTimeStatus = this.openTimeStatus.bind(this);
    this.unselect = () => this.setState({ selected: { date: '', index: -1 } });
    this.unselectTime = () => this.setState({ selectedTimeDate :'' });
    this.changeStatus = this.changeStatus.bind(this);
    this.changeTripTime = this.changeTripTime.bind(this);
    this.saveTripRecording = this.saveTripRecording.bind(this);
    this.saveAndApproveTripRecording = this.saveAndApproveTripRecording.bind(
      this
    );
    this.goToExpenses = this.goToExpenses.bind(this);
    this.getPreviousMonth = this.getPreviousMonth.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.unselect);
    if (!this.props.current.vanpoolers.length) {
      const dateObj = this.getPreviousMonth();
      const month = dateObj.Month
      const year =  dateObj.Year
      return this.props.init(month,year);
    }

    this.setState({ loading: false });
    this.updateDays(this.props[this.props.month].days);
    this.props.hasPreviousData(!!this.props.previous.vanpoolers.length);
  }

  getPreviousMonth() {
    const previousDate = dateUtility.previousDate();
    const Month =  dateUtility.getMonthFromDate(previousDate);
    const Year = dateUtility.getYearFromDate(previousDate);

    return {
      "Month": Month,
      "Year": Year
    };
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.unselect);
  }

  componentDidUpdate(){
    if(this.props.error && this.props.errorString && this.props.errorString.message){
      handleErrorScreen(this.props.errorString.message, this.props.history);
    }
  }

  componentWillReceiveProps(props) {
    if (props.month && props.month !== this.state.month) {
      const selectedMonthDays = this.state.currentMonthDays.length > 0 && props.month === 'current' ? 
                              this.state.currentMonthDays : this.state.previousMonthDays.length > 0 && props.month === 'previous' ?
                              this.state.previousMonthDays : this.props[props.month].days;
      return this.setState(
          {
            month: props.month,
            week: this.latestWeekOf(props.month),
            monthDays: selectedMonthDays
          },
          () =>
            this.props.current.vanpoolers.length &&
            this.updateDays(selectedMonthDays)
        );
      }

    if (props.error !== this.props.error) {
       if(this.state.currentMonthDays.length >0) {
         this.props.validation(this.state.currentMonthDays)
       } else if(this.state.previousMonthDays.length > 0) {
         this.props.validation(this.state.previousMonthDays);
       }
      return this.setState({ loading: !props.error });
    }

    if (props.current.vanpoolers.length && props.error === null) {
      this.setState({ monthDays: props[props.month].days, loading: false,currentMonthDays : [],previousMonthDays :[] });
      this.props.hasPreviousData(!!props.previous.vanpoolers.length);
      this.updateDays(props[this.state.month].days);
    }
  }

  latestWeekOf(month) {
    let date = moment();

    if (month === 'previous') {
      date = date.subtract(date.date(), 'day');
    }

    return dateUtility.weekOf(date);
  }

  updateDays(passedDays) {
    const previousYear = dateUtility.currentYear() - 1;
    const lastWeek = moment().year(previousYear).weeksInYear();
    const firstWeek = 1;

    let week;
    if (this.state.week === 0) {
      this.setState({ week: lastWeek });
      week = lastWeek;
    } else if (this.state.week === lastWeek + 1) {
      this.setState({ week: firstWeek });
      week = firstWeek;
    } else {
      week = this.state.week;
    }

    let days = passedDays.filter(day => dateUtility.weekOf(day.date) === week);

    if (!days.length) {
      logger.log('no days found, stopping early');
      logger.log('passed days', passedDays);
      return;
    }

    this.setState({ days, before: [], after: [] });

    if (days.length < 7) {
      const extra = new Array(7 - days.length).fill(null);
      let date = dateUtility.getMomentInFromDateFormat(days[0].date);
      date = date.add(-date.day(), 'day');

      if (dateUtility.dayOfWeek(days[0].date) !== 'Sun') {
        return this.setState({
          before: extra.map((_, i) => ({
            future: true,
            date: date
              .clone()
              .add(i, 'day')
              .format('YYYY-MM-DD')
          }))
        });
      }

      date = date.add(6, 'day');

      this.setState({
        after: extra
          .map((_, i) => ({
            future: true,
            date: date
              .clone()
              .add(-i, 'day')
              .format('YYYY-MM-DD')
          }))
          .reverse()
      });
    }
  }

  next() {
      this.setState({ week: this.state.week + 1 }, () =>
      this.updateDays(this.state.monthDays)
    );
  }

  prev() {
      this.setState({ week: this.state.week - 1 }, () =>
      this.updateDays(this.state.monthDays)
    );
  }
  openTimeStatus(date) {
    const { selectedTimeDate } = this.state;

    if (selectedTimeDate === date) {
    } else {
      this.setState({ selectedTimeDate:  date  });
    }
  }

  openStatus(date, index) {
    const { selected } = this.state;

    if (selected.date === date && selected.index === index) {
      this.unselect();
    } else {
      this.setState({ selected: { date, index } });
    }
  }

  changeTripTime(date,workStart,workEnd,homeStart,homeEnd,homeMilesData, workMilesData) {
    const originalDay = this.props[this.state.month].days.find(
      day => day.date === date
    );
    const homeMiles = homeMilesData ? parseFloat(homeMilesData) : 0
    const workMiles = workMilesData ? parseFloat(workMilesData) : 0
    const originalTripTime = originalDay.timedata;
    const isChanged =
    originalTripTime.homeBoundStartTime !== homeStart || 
    originalTripTime.homeBoundEndTime !== homeEnd ||
    originalTripTime.workBoundStartTime !== workStart ||
    originalTripTime.workBoundEndTime !== workEnd;

    const { days, monthDays } = this.state;
    const currentMonth = changeTime(monthDays, date, workStart,workEnd,homeStart,homeEnd,homeMiles,workMiles, isChanged);
    if(this.state.month === 'current') {
      this.setState({
        days: changeTime(days, date, workStart,workEnd,homeStart,homeEnd,homeMiles, workMiles, isChanged),
        monthDays: currentMonth,
        currentMonthDays : currentMonth
      }, function(){
        this.props.validation(this.state.days);
      });
    } else {
      this.setState({
        days: changeTime(days, date, workStart,workEnd,homeStart,homeEnd,homeMiles, workMiles, isChanged),
        monthDays: currentMonth,
        previousMonthDays : currentMonth
      }, function(){
        this.props.validation(this.state.days);
      });
    }
    

    this.unselectTime();
  }

  changeStatus(date, index, toWork, toHome) {
    const originalDay = this.props[this.state.month].days.find(
      day => day.date === date
    );
    const originalVanpooler = originalDay.vanpoolers[index];
    const isChanged =
      originalVanpooler.toHome !== toHome ||
      originalVanpooler.toWork !== toWork;
    const { days, monthDays } = this.state;
    const singleSelected = true
    const multiSelected = false
    const currentMonth = changeDay(monthDays, date, index, toWork, toHome, isChanged, singleSelected, multiSelected);
    if(this.state.month === 'current') {
      this.setState({
        days: changeDay(days, date, index, toWork, toHome, isChanged, singleSelected, multiSelected),
        monthDays: currentMonth,
        currentMonthDays : currentMonth,
      }, function(){
        this.props.validation(this.state.days);
      });
    } else {
      this.setState({
        days: changeDay(days, date, index, toWork, toHome, isChanged, singleSelected, multiSelected),
        monthDays: currentMonth,
        previousMonthDays : currentMonth,
      }, function(){
        this.props.validation(this.state.days);
      });
    }
    

    this.unselect();
  
  }

  selectDate(date) {
    const { selected } = this.state;

    if (selected.date === date && selected.wholeDay) {
      this.unselect();
    } else {
      this.setState({ selected: { date, wholeDay: true } });
    }
  }

  changeDate(date, toWork, toHome) {
    const originalDay = this.props[this.state.month].days.find(
      day => day.date === date
    );
    const { days, monthDays } = this.state;
    const multiSelected = true
    const singleSelected = false
    const newDays = originalDay.vanpoolers.reduce((days, vanpooler, index) => {
      const isChanged =
        vanpooler.toHome !== toHome || vanpooler.toWork !== toWork;
      return vanpooler.inVanpool
        ? changeDay(days, date, index, toWork, toHome, isChanged, singleSelected, multiSelected)
        : days;
    }, days);

    const newMonthDays = originalDay.vanpoolers.reduce(
      (days, vanpooler, index) => {
        const isChanged =
          vanpooler.toHome !== toHome || vanpooler.toWork !== toWork;
        return vanpooler.inVanpool
          ? changeDay(days, date, index, toWork, toHome, isChanged, singleSelected, multiSelected)
          : days;
      },
      monthDays
    );
    if(this.state.month === 'current') {
      this.setState({ days: newDays, monthDays: newMonthDays,currentMonthDays : newMonthDays },function(){
        this.props.validation(this.state.days);
      });
    } else {
      this.setState({ days: newDays, monthDays: newMonthDays,previousMonthDays : newMonthDays },function(){
        this.props.validation(this.state.days);
      });
    }
   

    this.unselect();
  }

  saveTripRecording() {
    this.setState({ loading: true });
    this.props.save(this.state.monthDays, this.state.month);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "save"
      }
    });
  }

  saveAndApproveTripRecording(monthToSave) {
    
    if (!this.state.acknowledged) {
      return this.setState({ showAcknowledgement: true });
    }

    this.setState({ loading: true });
    this.props.saveAndApprove(this.state.monthDays, monthToSave);
  }

  saveAction(submitted) {
    return submitted ? null : (
      <div className="action">
        <p>
          By clicking Save you confirm that the data in the calendar above is
          accurate.
        </p>
        <button className="button save" onClick={this.saveTripRecording}>
          Save
        </button>
      </div>
    );
  }


  goToExpenses() {
    window.location.hash = '#/trip-recording/expenses';
  }


  render() {
    const {
      days,
      before,
      after,
      month,
      loading,
      selected,
      selectedTimeDate,
      showAcknowledgement,
      requireExpenses
    } = this.state;

    if (!this.props.current || days.length === 0) {
      if (this.props.error) {
        return <Loading isLoading={false} />;
      } else {
        return <Loading isLoading={true} />;
      }
    }

    const { vanpoolers, days: viewingMonth } = this.props[month];
    const week = [...before, ...days, ...after];

    const canViewNextWeek =
      !after.length &&
      days[days.length - 1].date !== viewingMonth[viewingMonth.length - 1].date;
    const canViewPrevWeek =
      !before.length && days[0].date !== viewingMonth[0].date;
    const submitted =
      (!this.props.beforeEleventh || this.props.approved) &&
      this.props.month === 'previous';
    const prevMonth = dateUtility.parseDateAsFullMonth(
      moment().subtract(1, 'month')
    );

    return (
      <div>
        <div className={classList('trip-table', { submitted })}>
          <Loading isLoading={loading} />
          <FieldInfo message={<HotKeyInfo />}  aria-labelledby="tooltip info"/>
          <div className="column">
            <WeekNavigation
              days={days}
              prev={canViewPrevWeek ? this.prev : null}
              next={canViewNextWeek ? this.next : null}
            />
            <Vanpoolers vanpoolers={vanpoolers} />
           <DriveTimeDuration />
          </div>
          {week.map(day => (
            <DayStatus
              {...day}
              selectDay={e => e.stopPropagation() || this.selectDate(day.date)}
              changeDay={(toWork, toHome) =>
                this.changeDate(day.date, toWork, toHome)
              }
              changeStatus={this.changeStatus}
              openStatus={this.openStatus}
              selected={selected}
              key={day.date}
              selectedTimeDate={selectedTimeDate}
              openTimeStatus={this.openTimeStatus}
              closeDTTM= {this.openTimeStatus}
              changeTripTime={this.changeTripTime}
            />
          ))}
        </div>
        <Legend />
        <div className="tools">
          {this.saveAction(submitted)}
          {this.props.beforeEleventh &&
            !this.props.approved &&
            this.props.month === 'previous' && (
              <div className="action approval">
                <p>
                  By clicking Approve, you are acknowledging your data is
                  complete for {prevMonth}.
                </p>
                <button
                  className="button approve primary"
                  onClick={() => this.saveAndApproveTripRecording(prevMonth)}
                >
                  Approve
                </button>
              </div>
            )}
        </div>
        <ConfirmationPopup
          id="acknowledgement"
          title="Are you sure?"
          cancelMessage="Cancel"
          confirmMessage="Yes, Approve"
          cancel={() => this.setState({ showAcknowledgement: false })}
          confirm={() =>
            this.setState(
              { acknowledged: true, showAcknowledgement: false },
              () => this.saveAndApproveTripRecording(prevMonth)
            )
          }
          active={showAcknowledgement}
          message={`By approving, you are acknowledging your Trip and Expense data is complete for ${prevMonth}.  Your data will be submitted and will no longer be updatable.`}
        />
        <ConfirmationPopup
          id="expenses"
          title="Update Expenses"
          active={requireExpenses}
          cancel={() => this.setState({ requireExpenses: false })}
          confirm={() => this.goToExpenses()}
          message={`You can not approve Trip Data for ${prevMonth} until you have recorded Fuel expenses and Fuel Gallons. Please update and try again.`}
          cancelMessage="Cancel"
          confirmMessage="Record Expenses"
        />
        <RestrictFrom
          roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
          required={false}
        >
          <ImpactData section="vanpool" month={month} />
        </RestrictFrom>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state.tripRecording, expenses: state.expenses.expenses, wexTransactions: state.expenses.wexTransactions  };
}

function mapDispatchToProps(dispatch) {
  return {
    init(month,year) {
      dispatch({ type: actionTypes.TRIP_RECORDING_LOADING });
      // if (isAllowed([PERMISSION_NTD_TRIP_RECORDING])) {
      //   dispatch({ type: actionTypes.TRIP_EXPENSES_LOADING, data:{month:month,year:year}});
      // }
    },
    save(tripData, monthStatus) {
      dispatch({
        type: actionTypes.TRIP_RECORDING_SAVING,
        data: { tripData, monthStatus }
      });
    },
    saveAndApprove(tripData, monthToSave) {
      let monthStatus = false;
      dispatch({
        type: actionTypes.TRIP_RECORDING_SAVE_AND_APPROVE,
        data: { tripData, monthStatus, monthToSave }
      });
    },
    validation(data) {
      dispatch({
        type : actionTypes.TRIP_RECORDING_STATUS_VALIDATION,
        data : data
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
