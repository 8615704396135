import { FULL_MONTHS, FULL_WEEKDAYS } from "../inputs/Calendar/Calendar";

export function getAgeFromDOB(dob){
  if(dob){
    const diff = Date.now() - dob.getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}

export function getEndDateOfCancellation(currentDate){
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 10, 0);
}

export function getTodaysDay(weekdays){
  const today = new Date();
  const weekdayNumber = today.getDay();
  const todaysWeekday = weekdays[weekdayNumber];
  return todaysWeekday;
}

export function getMonthFromDateString(date){
  return new Date(date).getMonth();
}

export function getYearFromDateString(date){
  return new Date(date).getFullYear();
}

export function isToday(date) {
  const today = new Date();
  return date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();
}

export function getDaysForCalendar(weekdayOfFirstDay, firstDayOfMonth, selectedDay, enabledDays, startDay, endDay, presentMonth) {
  let days = [];
  const currentMonth = firstDayOfMonth.getMonth() - 1;
  // Normalize start and end days to midnight
  startDay.setHours(0, 0, 0, 0);
  endDay.setHours(0, 0, 0, 0);
  const daysInCalendar = 42;
  for(let day = 1; day <= daysInCalendar; day++) {
    if(day === 1 && (weekdayOfFirstDay === 1)) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 7);
    } else if(day === 1 && (weekdayOfFirstDay === 0)) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 6);
    }
     else if(day === 1) {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + (day - weekdayOfFirstDay));
    } else {
      firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
    }
    firstDayOfMonth.setHours(0,0,0,0);
    let strike = firstDayOfMonth <= startDay || firstDayOfMonth > endDay || !enabledDays.includes(firstDayOfMonth.getDay());
    let date = new Date(firstDayOfMonth);
    let selected = false
    if(date && selectedDay) {
      selected = date.getTime() === selectedDay.getTime();
    }
    let notThisMonth = true;
    if(firstDayOfMonth.getMonth() === presentMonth){
      notThisMonth = false;
    }

    const calendarDay = {
      date,
      month: firstDayOfMonth.getMonth() - 1,
      number: firstDayOfMonth.getDate(),
      year: firstDayOfMonth.getFullYear(),
      strike,
      selected,
      monthName: FULL_MONTHS[firstDayOfMonth.getMonth()],
      dayOfWeek: FULL_WEEKDAYS[firstDayOfMonth.getDay()],
      notThisMonth
    }
    days.push(calendarDay);
  }
      // Check if the last week belongs to the current month
      const lastWeek = days.slice(-7);
      const lastWeekOutsideMonth = lastWeek.every(d => d.month !== currentMonth);
    
      // Remove the last week if it is fully outside the current month
      if (lastWeekOutsideMonth) {
        days = days.slice(0, -7);
      }
  return days;
}