import React from 'react';
import dateUtility from '../../shared/dateUtility';
import TripStatus, { EditTripStatusPopup, keyDown } from './TripStatus';
import TimeStatus from './TimeStatus';

export default function(props) {
  const { date, future, selected, selectDay, vanpoolers, changeDay } = props;
  const isToday = dateUtility.isToday(date);
  const editing = selected.date === date && selected.wholeDay;
  const summation = editProps(vanpoolers);

  return (
    <div
      className={`day ${dateUtility.dayOfWeek(date).toLowerCase()} ${
        editing ? 'selected' : ''
      }`}
    >
      <div
        className={`label ${isToday ? 'current' : ''}`}
        tabIndex={future ? -1 : 0}
        onClick={future ? undefined : selectDay}
        onKeyDown={e =>
          keyDown(e.key, {
            editing,
            open: () => selectDay(e),
            change: changeDay,
            ...summation
          })
        }
        role='button' 
      >
        {editing ? (
          <EditTripStatusPopup {...summation} change={changeDay} />
        ) : null}
        <span  aria-label={`${dateUtility.dayOfWeek(date)} 'day'`} className="day-of-week">{dateUtility.dayOfWeek(date)}</span>
        <span className={`day-of-month ${future ? 'other-month' : ''}`}>
          {parseInt(date.split('-').pop(), 10)}
        </span>
      </div>
      <DayData {...props} />
    </div>
  );
}

export function DayData({
  date,
  vanpoolers,
  future,
  openStatus,
  changeStatus,
  selected,
  selectedTimeDate,
  timedata,
  closeDTTM,
  openTimeStatus,
  changeTripTime
}) {
  if (future) {
    return <div className="future"></div>;
  }

  const didNotCommute = vanpoolers.every(
    rider => rider.didNotCommute && !rider.isChanged
  );
  return (
    <div className={didNotCommute ? 'none' : ''}>
      {vanpoolers.map((rider, i) => (
        <TripStatus
          {...rider}
          editing={selected.date === date && selected.index === i}
          open={() => openStatus(date, i)}
          change={(toWork, toHome) => changeStatus(date, i, toWork, toHome)}
          isChanged={rider.isChanged}
          key={i}
        />
      ))}
      <TimeStatus
        {...timedata}
        editing={selectedTimeDate === date}
        selectedTimeDate={selectedTimeDate}
        open={() => openTimeStatus(date) }
        closeDTTM={(e) => closeDTTM("")}
        key={date}
        vanpoolers={vanpoolers}
        change={(date,workStart,workEnd,homeStart,homeEnd,homeMiles, workMiles) => changeTripTime(date,workStart,workEnd,homeStart,homeEnd,homeMiles, workMiles)}
      />
    </div>
  );
}

function editProps(vanpoolers) {
  if (!vanpoolers) {
    return { none: true };
  }

  const values = vanpoolers.map(rider => rider.toWork + 2 * rider.toHome);
  const first = values[0];

  if (values.every(value => value === first)) {
    return [
      { toWork: false, toHome: false },
      { toWork: true, toHome: false },
      { toWork: false, toHome: true },
      { toWork: true, toHome: true }
    ][first];
  }

  return { none: true };
}
