import React from 'react';
import { Prompt } from 'react-router-dom'
import CautionIcon from '../../../../_styles/images/Caution.svg';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { ESCAPE_KEY_CODE, TAB_KEY_CODE } from '../../../../shared/helpers/accessibilityHelpers';

export class RouteLeavingGuard extends React.Component {
  state = {
    modalVisible: false,
    lastLocation: null,
    confirmedNavigation: false,
  }
  showModal = (location) => this.setState({
    modalVisible: true,
    lastLocation: location,
  })
  closeModal = (callback, buttonName) => {
    buttonName && this.analyticsButtonClick(buttonName.toLowerCase());
    return this.setState({
      modalVisible: false
    }, callback)
  }
  handleBlockedNavigation = (nextLocation) => {
    const { confirmedNavigation } = this.state
    const { shouldBlockNavigation } = this.props
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      // analyticsService.analyticsProcessEvent({
      //   "event": "modal_view_generic",
      //   "context": {
      //     "event_action": "unsaved changes modal"
      //   }
      // });
      analyticsService.analyticsProcessEvent(
        {
          "event": "discard_changes_modal_view",
          "context": {
            "event_action": "discard changes modal"
          }
        });
      this.showModal(nextLocation)
      return false
    }
    return true
  }
  handleConfirmNavigationClick = () => {
    this.props.confirmButtonText && this.analyticsButtonClick(this.props.confirmButtonText.toLowerCase());
    return this.closeModal(() => {
    const { navigate } = this.props
    const { goBack } = this.props
    const { lastLocation } = this.state
    if (lastLocation) {
      this.setState({
        confirmedNavigation: true
      }, () => {
        this.props.handleValidation()
        // Navigate to the previous blocked location with your navigate function     
        this.setState({ confirmedNavigation: false });
        if (navigate) {
          navigate(lastLocation.pathname);
        } else if (goBack) {
          goBack();
        }
      })
    }
  })}

  keyDownTabHandler = (e) => {
    if (e.keyCode === TAB_KEY_CODE) {
      e.preventDefault();
      const firstFocusElement = document.querySelector('.confirmation-discard-button');
      firstFocusElement?.focus();
    }
  }

  keyDownBackTabHandler = (e) => {
    if (e.shiftKey && e.keyCode == TAB_KEY_CODE) {
      e.preventDefault();
      const lastFocusElement = document.querySelector('.confirmation-continue-button');
      lastFocusElement?.focus();
    }
  }

  close = (e) => {
    if (e.keyCode === ESCAPE_KEY_CODE) {
      const changesPopup = document.getElementById('delete-confirmation-container');
      if (changesPopup) {
        this.closeModal();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.close);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.close);
  }

  analyticsButtonClick(buttonName){
    analyticsService.analyticsProcessEvent({
      "event": "discard_changes_modal_click",
      "context": {
        "event_action": buttonName,
        "event_detail": {
            "modal": "discard changes modal"
        } 
      }
    })
  }

  render() {
    const { when, reference, heading, text, confirmButtonText, cancelButtonText } = this.props
    const { modalVisible } = this.state
    return (
      <div>
        <Prompt
          when={when}
          message={this.handleBlockedNavigation} />
        {modalVisible &&
          <div className='discard-popup card-popup show' ref={reference} role="alertdialog"
            id="dialog1"
            aria-labelledby="dialog1_label"
            aria-modal="true">
            <div className='card-operations-container' id='card-operations-container' aria-describedby='heading'>
              <div className='delete-confirmation-container' id='delete-confirmation-container'>
                <div className='caution-icon'>
                  <img src={CautionIcon} alt='warning image'></img>
                </div>
                <div className='heading' id='heading'>{heading}</div>
                <div className='foot-notes'>{text}</div>
                <div className='buttons'>
                  <button className='confirmation-discard-button' tabIndex={0} disabled={false} onClick={this.handleConfirmNavigationClick} onKeyDown={this.keyDownBackTabHandler}>{confirmButtonText}</button>
                  <button className='confirmation-continue-button' tabIndex={0} onClick={() => this.closeModal(() => {}, cancelButtonText || 'Keep editing')} onKeyDown={this.keyDownTabHandler}>{cancelButtonText || 'Keep editing'}</button>
                </div>
              </div>
            </div>
          </div>}
      </div>)
  }
}
export default RouteLeavingGuard