import React, { Component } from 'react';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import Stepper from './Stepper';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import PaymentForm from './payment-form/PaymentForm';
import PaymentReview from './review/PaymentReview';
import Confirmation from './confirmation/Confirmation';
import Questions from '../shared/Questions';
import { NEW_CARD } from './payment-form/components/NewCard';
import creditCardService from '../shared/credit-card/creditCardService';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_COORDINATOR,
  PERMISSION_IPB,
  PERMISSION_RIDER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';
import currencyFormatter from '../shared/currencyFormatter';

const STEP_FORM = 1;
const STEP_REVIEW = 2;
const STEP_CONFIRM = 3;

export class PaymentWizardPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areYouSure: false,
      currentStep: 1,
      payment: null
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(props) {
    if (props.payment.payment && this.state.currentStep === STEP_REVIEW) {
      this.next();
    }
  }

  reviewPayment(payment) {
    var formatedAmount = payment.amount.replace(/,/g, "");
    payment.amount = formatedAmount;
    this.setState({ payment });
    this.next();
  }

  cancel() {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      window.location.hash = '#/accountSummary';
    }
  }

  next() {
    if (this.state.currentStep < STEP_CONFIRM) {
      this.goToStep(this.state.currentStep + 1);
    }
  }

  previous() {
    if (this.state.currentStep > STEP_FORM) {
      const currentCard = this.state.payment.selectedCard.isNewCard
        ? NEW_CARD
        : this.state.payment.selectedCard;
      this.setState({
        payment: {
          ...this.state.payment,
          cvc: '',
          selectedCard: { ...currentCard }
        }
      });
      this.goToStep(this.state.currentStep - 1);
    }
  }

  goToStep(step) {
    window.scrollTo(0, 0);
    if (step === 1) {
      this.props.init();
    }
    this.setState({ currentStep: step });
  }

  getDefaultBalanceDue() {
    if (
      this.props.allocationAmount !== 'N/A' &&
      this.props.allocationAmount > 0
    ) {
      return this.props.allocationAmount;
    } else if (this.props.balanceDue !== 'N/A' && this.props.balanceDue > 0) {
      return this.props.balanceDue;
    }
    return null;
  }

  render() {
    const recurring = window.location.hash.includes('recurring');
    const modify = !window.location.hash.endsWith('recurring');
    const defaultDueAmount = this.getDefaultBalanceDue();
    const initialBalance =
      recurring || !defaultDueAmount
        ? null
        : currencyFormatter.dollarPad(defaultDueAmount.toString());

    const success =
      this.props.payment.payment && this.props.payment.payment.success;

    let wizard = {
      [STEP_FORM]: () => (
        <PaymentForm
          initialBalance={initialBalance}
          recurring={recurring}
          modify={modify}
          paymentMethods={this.props.payment.cards}
          payment={this.state.payment}
          review={payment => this.reviewPayment(payment)}
          cancel={() => this.setState({ areYouSure: true })}
        />
      ),
      [STEP_REVIEW]: () => (
        <PaymentReview
          recurring={recurring}
          modify={modify}
          payment={this.state.payment}
          vanpool={this.props.vanpoolId}
          cancel={() => this.setState({ areYouSure: true })}
          edit={() => this.previous()}
        />
      ),
      [STEP_CONFIRM]: () => (
        <Confirmation
          recurring={recurring}
          modify={modify}
          edit={() => this.goToStep(STEP_FORM)}
          payment={success ? this.state.payment : this.props.payment.payment}
          vanpool={this.props.vanpoolId}
          successful={success}
          transactionId={
            this.props.payment.payment
              ? this.props.payment.payment.transactionId
              : null
          }
        />
      )
    }[this.state.currentStep]();

    return (
      <div className="payment">
        <ConfirmationPopup
          title="Are you sure?"
          message="If you cancel, any information you've provided will not be saved. Are you sure you want to cancel?"
          cancelMessage="No, Continue"
          confirmMessage="Yes, cancel"
          cancel={() => this.setState({ areYouSure: false })}
          confirm={this.cancel}
          active={this.state.areYouSure}
        />
        <Stepper
          step={this.state.currentStep}
          recurring={recurring}
          modify={modify}
        />
        {wizard}
        <Questions />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    payment: state.payments,
    vanpoolId: state.vanpoolInfo.vanpoolId,
    balanceDue: state.dashboardPaymentSummary.currentAccountBalance,
    allocationAmount: state.paymentManagement.amount
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.PAYMENT_METHODS_LOADING });
      dispatch({ type: actionTypes.VANPOOL_INFO_LOADING });
      dispatch({ type: actionTypes.RESET_PAYMENT_RESPONSE });
      creditCardService.retrieveSchedule();
      if (
        !isAllowed([PERMISSION_IPB]) &&
        isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_COORDINATOR,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ])
      ) {
        dispatch({ type: actionTypes.BALANCE_DETAILS_LOADING });
      }
      if (!isAllowed([PERMISSION_IPB]) && isAllowed([PERMISSION_RIDER])) {
        dispatch({ type: actionTypes.PAYMENT_ALLOCATION_LOADING });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentWizardPresentation);
