import * as actionTypes from '../../redux/actionTypes';

import { useEffect, useState } from 'react';

import AddressUtility from '../../shared/addressUtility';
import CloseMatchNotification from './close-match-notification/CloseMatchNotification';
import EditCommutePopup from './editCommutePopup';
import PersonalSavings from './personalSavings';
import Loading from '../../shared/loading/Loading';
import Location from '../../_styles/images/Location.png';
import LocationPin from '../../_styles/images/LocationPin.png';
import MapCenter from '../../_styles/images/MapCenter.png';
import MapService from '../../shared/map-service';
import {RouteDetails} from '../common/route-details/RouteDetails';
import analyticsService from '../../shared/analytics/analytics-service';
import Road from '../../_styles/images/Road.png';
import Seat from '../../_styles/images/Seat.png';
import VanRouteMap from './VanRouteMap';
import VanpoolPopup from './VanpoolPopup';
import { connect } from 'react-redux';
import { decode } from "@googlemaps/polyline-codec";
import { getActiveDaysFromRoosterDays, getPackageFromDays, getVanPrice } from '../helpers/vanHelpers';
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import isEqual from 'lodash/isEqual';
import { numberWithCommas } from '../helpers/commonHelper';
import suitcase from '../../_styles/images/suitcase.png';
import ImageNotAvailable from '../../_styles/images/ImageNotAvailable.svg';
import EditCommuteDays from './edit-commute-days/EditCommuteDays';
import LetUsHelpYouSection from './let-us-help-you/LetUsHelpYouSection';
import PriceDisclaimerPopup from '../subscription/PriceDisclaimerPopup';
import TraditionalDisclaimerPopup from './TraditionalDisclaimerPopup';
import { WEEKDAYS } from '../../shared/inputs/Calendar/Calendar';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

function VanpoolDetails(props) {

  const [vanpool, updateVanpoolDetails] = useState({});
  const [impactMetrics, updateImpactMetrics] = useState([])
  const [active, setActive] = useState(false);
  const [type, setType] = useState({ traditional: false, isPredelivery: false })
  const [visible, setVisibility] = useState(false);
  const [selectedDays, updateSelectedDays] = useState([]);
  const [exactMatch, updateExactMatch] = useState(false);
  const [showPDPopup, updatePDPopup] = useState(false);
  const [showTDPopup, updateTDPopup] = useState(false);

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    const close = (e) => {
      if (e.keyCode === 27) {
        const popup = document.getElementById('edit-commute-popup');
        if (popup) {
          setVisibility(false);
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, []);

  useEffect(() => {
    if (props.savedTraditonalInfo) {
      props.updateLoading(false);
      analyticsService.pageInfo("lets ride together thank you page");
      props.history.push("/JoinCommute/vanpool-details/thankyou");
    }
  }, [props.savedTraditonalInfo]);

  useEffect(() => {
    if (props.joinCommuteData && props.joinCommuteData.selectedVan) {
      updateVanpoolDetails(props.joinCommuteData.selectedVan);
      getMapDirections(props.joinCommuteData.selectedVan);
      updateExactMatch(props.joinCommuteData.selectedVan.isOptimalMatch);
    }
    if (props.impactMetrics) {
      updateImpactMetrics(props.impactMetrics);
    }
    if (props.joinCommuteData.userCommuteData && props.joinCommuteData.userCommuteData.isUpdated) {
      const days = getActiveDaysFromRoosterDays(props.joinCommuteData.userCommuteData.rosterDays)
      updateSelectedDays(days);
    }

  }, [props.impactMetrics, props.joinCommuteData]);



  async function getAddressUtility(address) {
    let addressString = await MapService.getAddressAndLocation(address);
    var addressObj = AddressUtility.fromGeocode(addressString);
    return addressObj
  }

  function getCommuteDays(daysArray) {
    var commuteDays = [];
    WEEKDAYS.forEach((day, ind) => { if (daysArray[day] === true) { commuteDays.push(ind + 1); } })
    return commuteDays;
  }


  function getMapDirections(van, recenter) {
    var path = []; var directions = [];
    var polyLine = decode(van.polyLine);
    polyLine.map((p) => {
      var obj = {
        "latitude": p[0],
        "longitude": p[1]
      }
      path.push(obj)
    })
    directions.push(path[0]);
    directions.push(path[path.length - 1]);
    props.setDirections(directions);
    var origin = {
      ...van.matchedOriginStop,
      reCenter: recenter
    }
    props.setOrigin(origin);
  }

  function navigateToChoosePlan() {
    if(props.userInfo && props.userInfo.vanpoolId && !props.userInfo.isSubscription) {
      saveTraditionalInfo();
    }else{
      if(vanpool.isPredelivery){
        analyticsService.analyticsProcessEvent({
          "event": "forming_commute_modal_click",
          "context": {
            "event_action": "continue",
            "event_detail":  "forming commute modal",
            }
        });
      }
      return props.history.push("/JoinCommute/choose-plan");
    }
  }

  async function saveTraditionalInfo() {
    var requestObj = {
      ...props.joinCommuteData.employerData,
      "firstName": props.userInfo.firstName,
      "lastName": props.userInfo.lastName,
      "emailAddress": props.profile.email,
      "phone": {
        "number": props.userInfo.phone && props.userInfo.phone.number
      },
      "homeAddress": await getAddressUtility(props.joinCommuteData.userCommuteData.homeAddress),
      "workAddress": await getAddressUtility(props.joinCommuteData.userCommuteData.workAddress),
      "workArrivalTime": props.joinCommuteData.userCommuteData.arriveTime,
      "workDepartureTime": props.joinCommuteData.userCommuteData.departTime,
      "isWorkArriveTimeFlexible": props.joinCommuteData.userCommuteData.flexArrivalMinutes !== 0,
      "isWorkDepartTimeFlexible": props.joinCommuteData.userCommuteData.flexDepartureMinutes !== 0,
      "commuteDays": getCommuteDays(props.joinCommuteData.userCommuteData.rosterDays),
      "arriveFlexTimeInMinutes": props.joinCommuteData.userCommuteData.flexArrivalMinutes,
      "departureFlexTimeInMinutes": props.joinCommuteData.userCommuteData.flexDepartureMinutes,
      "vanpoolMatch": [props.joinCommuteData.selectedVan]
    };
    setActive(active ? !active : false);
    analyticsService.analyticsProcessEvent({
      "event": `${vanpool.isPredelivery ? "forming_commute_modal_click" : "traditional_commute_modal_click" }`,
      "context": {
        "event_action": "continue",
        "event_detail": `${vanpool.isPredelivery ? "forming commute modal" : "traditional commute modal"}`
        }
    });
    props.updateLoading(true);
    props.saveTradionalDetails(requestObj);
  }

  function openCloseModal(text) {
    if(text == "booknow"){
      analyticsService.analyticsProcessEvent({
        "event": "click_book_now" ,
        "context": {
          "event_action": "book now"
          }
      });
    }
    if (vanpool.isSubscriptionVanpool && !vanpool.isPredelivery) {
      if(props.userInfo && props.userInfo.vanpoolId && !props.userInfo.isSubscription) {
       return  saveTraditionalInfo();
      }else{
        navigateToChoosePlan();
      }
    }
    setType({ traditional: !vanpool.isSubscriptionVanpool, isPredelivery: vanpool.isPredelivery })
    setActive(!active)
    if(!vanpool.isSubscriptionVanpool || vanpool.isPredelivery ) {
      if(text == 'close'){
        analyticsService.analyticsProcessEvent({
          "event": `${vanpool.isPredelivery ? "forming_commute_modal_click" : "traditional_commute_modal_click" }`,
          "context": {
            "event_action": "close x",
            "event_detail": `${vanpool.isPredelivery ? "forming commute modal" : "traditional commute modal" }`
            }
        });
      }
      else{
        analyticsService.analyticsProcessEvent({
          "event": `${vanpool.isPredelivery ? "forming_commute_modal_view" : "traditional_commute_modal_view" }`,
          "context": {
            "event_action": `${vanpool.isPredelivery ? "forming commute modal" : "traditional commute modal" }`
            }
        });
      }
    }
  }

  function openEditCommuteModal() {
    setVisibility(true);
    analyticsService.analyticsProcessEvent({
      "event": "click_edit_my_commute",
      "context": {
        "event_action": "edit my commute",
        }
    });
  }

  function commuteUpdated(data, newDays) {
    setVisibility(false);
    updateSelectedDays(newDays);
    props.updateEditCommuteInfo(true);
    props.updateUserCommuteData(data);
    if(props.joinCommuteData){
      let van = props.joinCommuteData.selectedVan;
      const userPack = getPackageFromDays(newDays.length)
      const vanCharge = getVanPrice(props.joinCommuteData.selectedVan, props.joinCommuteData.drivingScheduleData.isDesiredDriverRole, userPack);
      const vanChargeRider = getVanPrice(props.joinCommuteData.selectedVan, false, userPack);
      van.charge = vanCharge;
      van.riderCharge = vanChargeRider;
      props.setSelectedVan(van);
    }
  }

  function goToStayInTouch() {
    analyticsService.analyticsProcessEvent({
      "event": "click_let_us_help_you",
      "context": {
        "event_action": "let us help you",
        }
      });
    return props.history.push("/JoinCommute/stay-in-touch");

  }

  function closeEditCommuteModal() {
    setVisibility(false);
    props.updateEditCommuteInfo(true);
  }

  return (

    <div className='vanpool-details-container'>
      <span aria-live='polite' role='alert'>
        <Loading isLoading={props.loading} />
      </span>
      <div className="vanpool-details" role='group'>
        <div className="vanpool-details-top">
          <div className="vanpool-image" role='group'>
            <div className={`${vanpool.isPredelivery ? 'car-image-opacity' : 'car-image'}`}>
              <img src={vanpool.carClassImage} onError={e => {
                e.currentTarget.src = ImageNotAvailable;
                e.currentTarget.className = "image-not-available";
              }} alt='van image' />
            </div>
            <div className="van-details">
              <div className="van-class-details" role='group'>
                <div className="van-class">{vanpool.vehicleClass}</div>
                <div className="van-model">{vanpool.ymms}</div>
              </div>
              {props.joinCommuteData.selectedVan && (!exactMatch) ? <div className="close-match-label">Close Match</div> : null}
              <div className="van-other-details" role='group'>
                <div className="private">{vanpool.isPrivate &&
                  <div className='employer'>
                    <img src={suitcase} alt="" />
                    Employer
                  </div>
                }
                </div>
              </div>
            </div>
            {
              props.joinCommuteData.selectedVan && exactMatch === false && (
                ((selectedDays.length === 0 && !props.joinCommuteData.userCommuteData.isUpdated) &&
                  <CloseMatchNotification openEditCommuteModal={() => setVisibility(true)} />
                ))}
            <div className="oprational-days-heading ">{"Days of Operation"}</div>
            <div className="oprational-days" role='group'>

              {vanpool.operationalDays && Object.keys(vanpool.operationalDays).filter(d => vanpool.operationalDays[d] && vanpool.operationalDays[d].operational).map(day => ({
                day,
                active: vanpool.operationalDays[day].available
              })).map(({ day, active, disabled }) => (
                <button
                  day={day}
                  key={day}
                  type="button"
                  disabled={true}
                  className={`day-btn ${active ? 'active' : 'not-active'}`}
                  aria-label={day + (active ? " Open" : " Full")}
                >
                  {day.substr(0, 3)}
                  {<span className="available">{active ? "Open" : "Full"}</span>}
                </button>
              ))}
            </div>
          </div>
          <div className="vanpool-map" role='group'>
            <div className="map">
              <VanRouteMap />
              <div className="re-center">
                <img role='button' src={MapCenter} onClick={() => getMapDirections(vanpool, true)} alt='re-center map' />
              </div>

            </div>
            <RouteDetails vanpool={vanpool} role='group' />
          </div>
        </div>
        <div className="vanpool-details-bottom">
          <PersonalSavings {...impactMetrics} selectedDays={selectedDays} goToStayInTouch={goToStayInTouch} isUpdated={props.joinCommuteData.userCommuteData.isUpdated} isSubscriptionVanpool={vanpool.isSubscriptionVanpool} charge={vanpool.charge} openCloseModal={() => openCloseModal()} isOptimalMatch={exactMatch} editCommute={() => setVisibility(true)} isDesktop={false} updatePDPopup={() => updatePDPopup(true)} updateTDPopup={() => updateTDPopup(true)} isSubsidized={vanpool.isSubsidized}/>
          <div className="van-deatils-route">
            <div className="vanpool-distance">
              <div className="vanpool-distance-details" aria-label={vanpool.matchedOriginWalkingDistanceInMiles + ' miles' + ' to pickup'}>
                <img src={Location} alt='' />
                <div className="value">{vanpool.matchedOriginWalkingDistanceInMiles}</div>
                <div className="key">{"to pickup"}</div>
              </div>
              <div className="vanpool-distance-details" aria-label={vanpool.matchedDestinationWalkingDistanceInMiles + ' miles' + ' To dropoff'}>
                <img src={LocationPin} alt='' />
                <div className="value">{vanpool.matchedDestinationWalkingDistanceInMiles}</div>
                <div className="key">{"To dropoff"}</div>
              </div>
              <div className="vanpool-distance-details" aria-label={vanpool.vehicleCapacity + ' seats'}>
                <img src={Seat} alt='' />
                <div className="value">{vanpool.vehicleCapacity}</div>
                <div className="key">{"Seats"}</div>
              </div>
              <div className="vanpool-distance-details" aria-label={vanpool.totalRouteDistanceForRider?.toFixed(1) + ' miles' + ' distance'}>
                <img src={Road} alt='' />
                <div className="value">{vanpool.totalRouteDistanceForRider?.toFixed(1)}</div>
                <div className="key">{"distance"}</div>
              </div>
            </div>
            
            {
              exactMatch === false && 
              ((selectedDays.length || props.joinCommuteData.userCommuteData.isUpdated) ?
                  <EditCommuteDays selectedDays={selectedDays} openEditCommuteModal={() => setVisibility(true)}  isDesktop={true}/>
                  :
                  <CloseMatchNotification openEditCommuteModal={() => setVisibility(true)}  isDesktop={true}/>
                )}
            <div className="desktop-only-sponsored">
              {vanpool.isSubsidized && <SponsoredVanpool />}
            </div>
            {
              !exactMatch && vanpool.isSubscriptionVanpool &&
              <LetUsHelpYouSection isSelected={selectedDays.length !== 0} goToStayInTouch={goToStayInTouch} isMobile={false} />
            }
            {/* {
             !exactMatch  && !vanpool.isSubscriptionVanpool &&
             <CloseMatchNotification isDesktop={true}/>
            } */}
            <div className="booknow-container desktop">
            { vanpool.isSubscriptionVanpool ? (
                <div className="booknow">
                  <div className="total">Total</div>
                  <div className="van-amount" role='group'>
                    <div className='price-group' aria-label={`${vanpool.charge.price} dollars and ${vanpool.charge.decimal} cents`}>
                      <div className="dollar">{'$'}</div>
                      <div className="price">{numberWithCommas(vanpool.charge.price)}</div>
                      <div className="cents">.{vanpool.charge.decimal}</div>
                    </div>
                    <div className="month">{'per month'}</div>
                  </div>
                </div>

              ) :
                vanpool && vanpool.charge && (
                <div className="booknow traditional">
                  <div className='total-disclaimer'>
                    <div className="total">
                      Total
                      </div>
                    <div className='traditional-disclaimer'>
                      Estimate only.&nbsp;
                    </div>
                    <span onClick={() => updateTDPopup(true)}>Learn More</span>
                  </div>
                <div className="van-amount" role='group'>
                  <div className='price-group' aria-label={`${vanpool.charge.price} dollars and ${vanpool.charge.decimal} cents`}>
                    <div className="dollar">{'$'}</div>
                    <div className="price">{numberWithCommas(vanpool.charge.price)}</div>
                    <div className="cents">.{vanpool.charge.decimal}</div>
                  </div>
                  <div className="month">{'per month'}</div>
                </div>
              </div>
              
              )
              }
              {
                !vanpool.isSubscriptionVanpool ?
                <button onClick={() => openCloseModal()} className="check-now-button" >CONTINUE</button>
                :
                (exactMatch || (!exactMatch && selectedDays.length > 0)) ?
                  <button onClick={() => openCloseModal("booknow")} className="book-now-button" >BOOK NOW</button> :
                  <button onClick={openEditCommuteModal} className={'edit-commute-button secondary'} >EDIT MY COMMUTE</button>

              }
              {
              !exactMatch &&
              <LetUsHelpYouSection isSelected={selectedDays.length !== 0} goToStayInTouch={goToStayInTouch} isMobile={true} />
             }
            </div>
            {
              !exactMatch && !vanpool.isSubscriptionVanpool &&
              <LetUsHelpYouSection isSelected={selectedDays.length !== 0} goToStayInTouch={goToStayInTouch} isMobile={false} isTraditional = {!vanpool.isSubscriptionVanpool} />
              }
            {
              vanpool.isSubscriptionVanpool && 
              <div className='disclaimer desktop' role='group'>
                <p>
                Recurring subscription. 
                Rates subject to change.<span>&nbsp;</span>
                <span onClick={() => updatePDPopup(true)}>Learn More</span>
                </p>
            </div>
            }

          </div>
        </div>
      </div>
      <span aria-live='polite' role='alert'>
        <VanpoolPopup active={active} goToChoosePlan={navigateToChoosePlan} saveTraditionalInfo={saveTraditionalInfo} close={() => openCloseModal("close")} type={type} />
      </span>
      <span aria-live='polite' role='alert'>
        {
          visible ? <EditCommutePopup updateCommute={commuteUpdated} close={closeEditCommuteModal} /> : null
        }
      </span>
      {
          showPDPopup &&  <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
      }
      {
          showTDPopup && <TraditionalDisclaimerPopup  updateTDPopup={() => updateTDPopup(false)}/>
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    impactMetrics: state.joinCommute.impactMetrics,
    joinCommuteData: state.joinCommute,
    profile: state.profile,
    userInfo: state.userAuth,
    savedTraditonalInfo: state.joinCommute.userInfoSaved,
    loading: state.joinCommute.loading,
    editCommute: state.joinCommute.editCommute
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    },
    setOrigin(origin) {
      dispatch({ type: actionTypes.MAP_ORIGIN, data: origin });
    },
    saveTradionalDetails(value) {
      dispatch({ type: actionTypes.SET_USER_INFO, data: value });
    },
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    updateUserCommuteData(data) {
      dispatch({ type: actionTypes.UPDATE_USER_COMMUTE_DATA, data, updateOldPlan: true });
    },
    updateEditCommuteInfo(popupOpened) {
      dispatch({ type: actionTypes.SAVE_EDIT_COMMUTE_INFO, data: {popupOpened}});
    },
    setPlanData(data) {
      dispatch({type: actionTypes.SET_PLAN_DATA,data})
    },
    setSelectedVan(van){
      dispatch({type: actionTypes.SET_SELECTED_VAN, data: van})
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VanpoolDetails)