import { useEffect, useRef, useState } from 'react';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE, TAB_KEY_CODE } from '../../../../shared/helpers/accessibilityHelpers';

export default function StateDropdown(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(props.options);

  const optionRefs = useRef([]);
  const node = useRef();

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  function optionClicked(opt) {
    setIsOpen(false);
    setSearchTerm('');
    props.onChange(opt);
  }

  var tempClass = 'time-arrow ';
  tempClass += isOpen ? 'up ' : 'down ';

  var dropdownClass = 'dropdown-list ';
  dropdownClass += isOpen ? 'unhide' : 'hide';


  const handleInputChange = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const matchedOptions = props.options.filter((option) =>
      option.toLowerCase().includes(searchValue)
    );

    setFilteredOptions(matchedOptions);
  };
  const clickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    setIsOpen(false)
    if (!props.value) {
      resetAll();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    }
  }, [isOpen])

  useEffect(() => {
    if (focusedIndex >= 0 && optionRefs.current[focusedIndex]) {
      optionRefs?.current[focusedIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [focusedIndex]);

  function resetAll() {
    setFocusedIndex(-1);
    props.onChange('');
    setSearchTerm('');
    setFilteredOptions(props.options);
  }

  function onKeyDown(e) {
    if (isOpen) {
      const totalOptions = props.options.length;
      if (e.key === 'ArrowDown') {
        setFocusedIndex((prevIndex) => (prevIndex + 1) % totalOptions);
      } else if (e.key === 'ArrowUp') {
        setFocusedIndex((prevIndex) => (prevIndex - 1 + totalOptions) % totalOptions);
      } else if (e.which === ENTER_KEY_CODE) {
        if (focusedIndex >= 0) {
          optionClicked(filteredOptions[focusedIndex]);
          setIsOpen(false);
          setFocusedIndex(-1);
        } else {
          toggle();
          resetAll();
        }
      } else if (e.which === ESCAPE_KEY_CODE) {
        setIsOpen(false);
      }
    } else if (e.which === TAB_KEY_CODE) {

    } else {
      toggle();
      resetAll();
    }

  }

  function onStateSelectKeyDown(e, option) {
    if (e.which === ENTER_KEY_CODE) {
      optionClicked(option);
    }
  }

  function onFocusInput() {
    setIsOpen(true);
    resetAll();
  }


  return (<div className='flexibility-container' ref={node}>
    <div className='flexibility-label'>
      <label id={props.name}>{props.label || ' '}</label>
    </div>
    <div>
        <input
          type="text"
          className="dropdown-input"
          value={props.value ? props.value : searchTerm}
          placeholder={'State'}
          onChange={handleInputChange}
          onFocus={onFocusInput}
          onClick={onFocusInput}
          onKeyDown={onKeyDown}
          aria-autocomplete="list"
          aria-controls={`${props.name}-listbox`}
          aria-expanded={isOpen}
        />
    </div>
    {isOpen && <div className='dropdown-container' id={`${props.name}-listbox`} role='listbox' aria-activedescendant={props.value}>
      <div className={dropdownClass}>
        {filteredOptions.map((option, index) => (
          <div
            ref={(el) => (optionRefs.current[index] = el)}
            role="option"
            key={option}
            className={`list-item ${index === focusedIndex ? 'focussed' : ''}`}
            aria-selected={option === props.value}
            onClick={() => optionClicked(option)}
            onKeyDown={(e) => onStateSelectKeyDown(e, option)}
            tabIndex={-1}
          >
            {option}
          </div>
        ))}
        {filteredOptions.length === 0 && (
          <div className="no-options">No matching options</div>
        )}
      </div>
    </div>}
  </div>);
}