import { Link } from 'react-router-dom';
import ArrowLeft from '../../../../_styles/images/ArrowLeft.png';
import SteeringWheel from '../../../../_styles/images/new-icons/SteeringWheel.svg';
import { RouteDetails } from '../../../../join-commute/common/route-details/RouteDetails';
import ErrorCircle from '../../../../_styles/images/error-circle.svg';
import DollarSign from '../../../../_styles/images/new-icons/DollarSign.svg';
import { connect } from 'react-redux';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import * as actionTypes from '../../../../redux/actionTypes';
import Loading from '../../../../shared/loading/Loading';
import { getDaysFromPackage } from '../../../../join-commute/helpers/vanHelpers';
import { isEqual } from 'lodash';
import PaymentCard from '../../../../payment/common/paymentCard';
import { getSortedCards, makeDecimal2numbers, splitNumber } from '../../../../payment/helper';
import { assignPriceToCards } from '../../../../shared/helpers/cardHelpers';
import DatePickerCustom from '../../../../shared/components/summary/date-picker/DatePickerCustom';
import { addDays, getDaysFromRosterDays, getUpdatedRoosterDays, numberWithCommas } from '../../../../join-commute/helpers/commonHelper';
import Calendar from '../../../../shared/inputs/Calendar/Calendar';
import ChangeSubscriptionConfirmation from '../../change-subscription/confirmation/ChangeSubscriptionConfirmation';
import dateUtility from '../../../../shared/dateUtility';
import DiscardChanges from '../../common/discardChangesPopup/DiscardChanges';
import PriceDisclaimerPopup from '../../../../join-commute/subscription/PriceDisclaimerPopup';
import TaxesAccordion from '../../../../payment/common/TaxesAccordion';
import RateAccordion from '../../../../payment/common/RateAccordion';

function ChangeSubApplyToDriveSummary(props) {

  const node = useRef(null);
  const navigationAlertNode = useRef();

  const [days, updateDays] = useState();
  const [isLoading, updateIsLoading] = useState(false);
  const [selectedDay, updateSelectedDay] = useState();
  const [isOpen, updateIsOpen] = useState(false);
  const today = new Date(new Date());
  const [calendarInfo, updateCalendarInfo] = useState({
    startDay: today,
    endDay: addDays(30, today)
  });
  const [enabledDays, updateEnabledDays] = useState([]);
  const [driverRate, updateDriverRate] = useState({
    price: 0,
    decimal: 0,
    rate: 0,
    tax: 0,
    totalAmount: 0,
  });
  const [paymentCards, updatePaymentCards] = useState([]);
  const [showConfirmation, updateShowConfirmation] = useState(false);
  const [subscriptionStartDay, updateSubscriptionStartDay] = useState('');
  const [packageDays, updatePackageDays] = useState();
  const [showPDPopup, updatePDPopup] = useState(false);
  const [isJustSwitchingToDrive, updateIsJustSwitchingToDrive] = useState(false);

  useEffect(() => {
    if (props.profile) {
      if (props.profile.paymentCards) {
        const sortedCards = getSortedCards(props.profile.paymentCards);
        const amount = parseFloat(props.selectedPlan.totalAmount);
        const chargedCards = assignPriceToCards(amount, sortedCards);
        updatePaymentCards(chargedCards);
      }
    }
  }, [props.profile, props.selectedPlan])

  useEffect(() => {
    if (props.selectedPlan) {
      if (props.selectedPlan.days) {
        updateDays(props.selectedPlan.days);
        const runningDays = getDaysFromRosterDays(props.selectedPlan.days);
        updateEnabledDays(runningDays);
      }
      if (props.selectedPlan.packageName) {
        const pack = getDaysFromPackage(props.selectedPlan.packageName);
        updatePackageDays(pack);
      }
      if (props.selectedPlan && !props.selectedPlan.isEnableFromTomorrow) {
        const startDay = calendarInfo.endDay;
        const endDay = addDays(60, startDay);
        updateCalendarInfo({
          startDay,
          endDay
        });
      }
      updateIsJustSwitchingToDrive(props.selectedPlan.isJustSwitchingToDrive);
    }
  }, [props.selectedPlan])

  useEffect(() => {
    if (isEqual(props.selectedPlan, {})) {
      navigateToProfileOverview();
    }
  }, []);

  useEffect(() => {
    const price = props?.selectedPlan?.totalAmount && splitNumber(props.selectedPlan.totalAmount);
    updateDriverRate(price);
  }, [props?.selectedPlan?.totalAmount]);

  function navigateToProfileOverview() {
    return props.history.push("/myprofile");
  }

  function saveChangeSubscription() {
    const effectiveDate = selectedDay && dateUtility.getDateStringFromDay(selectedDay);
    const rosterDays = getUpdatedRoosterDays(props.selectedPlan.days, true);
    let data = {
      packageDiscountType: props.selectedPlan.role === 'driver' ? "Driver" : "Rider",
      packageName: props.selectedPlan.packageName,
      rosterDays,
      subscriptionType: "Fixed",
      agreementId: props.contractInfo.agreementId,
      clickwrapId: props.contractInfo.clickwrapId
    }
    if(effectiveDate){
      data.effectiveDate = effectiveDate;
    }
    if (props.selectedPlan && props.selectedPlan.isRoleChange && props.selectedPlan.role === 'driver' && props.driverApplicationData && props.driverApplicationData.apiData && props.driverApplicationData.apiData.driverCoordInformation) {
      data = {
        ...data,
        driverCoordInformation: {
          ...props.selectedPlan.driverRoleInformation,
          ...props.driverApplicationData.apiData.driverCoordInformation,
          agreementId: props.contractInfo.agreementId,
          clickwrapId: props.contractInfo.clickwrapId
        }
      }
    }
    const startSub = dateUtility.parseDateAsMonthDayYearFromGMT(selectedDay);
    updateSubscriptionStartDay(startSub);
    updateIsLoading(true);
    props.changeSubscription(data);
  }

  function toggleCalendar() {
    if (!isOpen) {

      const modal = document.getElementById("modal");
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "true");
      updateIsOpen(true);
      if (modal) {
        modal.setAttribute("aria-modal", "true");
        modal.querySelector(".calendar-day").focus();
      }
    } else {
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }

  }

  function onCalendarKeydown(e) {
    if (e.which == 13) {
      toggleCalendar();
    }
  }

  function checkAndCloseCalendar() {
    const modal = document.getElementById("modal");
    const classes = Array.from(modal.classList);
    if (classes.includes('show')) {
      const all = document.querySelector(".apply-to-drive-page");
      all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }
  }

  const clickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    }
    toggleCalendar();
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', clickOutside);
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }
  }, [isOpen])

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        checkAndCloseCalendar()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, []);

  function closeChangeSubscriptionPopup() {
    updateShowConfirmation(false);
    updateSelectedDay('');
    props.history.push('/myprofile');
  }

  useEffect(() => {
    if (props.changed === true) {
      updateShowConfirmation(true);
    } else if (props.changed === false) {
      props.changeSubscriptionReset();
      updateSelectedDay(null);
      props.history.push('/myprofile');
    }
    updateIsLoading(false);
  }, [props.changed])

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={isLoading} />
      <div className='profile-edit-page apply-to-drive-page'>
        <div className='view-container apply-to-drive'>
          <div className='header'>
            <div className='backArrow'>
              <Link className="overview-link" onClick={props.history.goBack}>
                <img alt='go back' src={ArrowLeft}></img>
              </Link>
            </div>
            <div className='heading'>Summary</div>
          </div>
          <div className='content-container'>
            <div className='thanks-content'>
              <img src={SteeringWheel} aria-hidden='true' />
              <div className='thanks-text'>Thanks for applying to be a driver! Your plan will be updated upon approval.</div>
            </div>
            <div className='plan-card' role='group'>
              <div className='plan-content' role='group'>
                <div className='badge primary'>Updated Plan</div>
                {packageDays && packageDays > 4 ?
                  <div className='plan'>Unlimited Plan</div>
                  :
                  <div className='plan'>{packageDays}-Day Plan</div>
                }
              </div>
              <div className='plan-content' role='group'>
                <div className='rate-badge'>
                  <img src={SteeringWheel} aria-hidden='true' />Driver rate</div>
                <div className="rate">
                  <div className="amount" role='text'>
                    <div aria-hidden="true" className="price-group">
                      <div className="dollar">{'$'}</div>
                      <div className="price">{numberWithCommas(driverRate.price)}</div>
                      <div className="point">.</div>
                      <div className="decimal">{driverRate.decimal}</div>
                    </div>
                    <span>
                      <span className="screenreader-only">{driverRate.price} dollars and {driverRate.decimal} cents total per month</span>
                    </span>
                  </div>
                </div>
                <div aria-hidden='true' className='per-month'>total per month</div>

              </div>
            </div>
            <hr aria-hidden='true' />
            <div className='van-info'>
              <div className='section first'>
                <div className='top' role='group'>
                  <div className='left'>
                    <div className='van-name'>{props.profile?.vanpool?.name}</div>
                    <div className='van-type'> {props.profile?.vanpool?.vehicleType}</div>
                  </div>
                  <div className='right'>
                    <img className='van-image' src={props.profile.vanpool?.vanpoolImage} alt={props.profile.vanpool.vehicleType} />
                  </div>
                </div>
                <div className='bottom'>
                  <div className='days-section' role='group'>
                    <div className='commuteDays-heading'>Commute days</div>
                    <div className='commute-days'>
                      {days && days.map((day, index) => {
                        return <div className='block' aria-label={day} key={index}>{day.substring(0, 3)}</div>
                      })}
                    </div>
                    <div className='blue-notes'>
                      <div className='warning-icon'>
                        <img className='error-circle' src={ErrorCircle} aria-hidden='true' />
                      </div>
                      Your commute days can be changed later from your profile
                    </div>
                  </div>
                </div>
              </div>
              <div className='section'>
                {props.profile.commuteDetails && <RouteDetails vanpool={{
                  originAddress: props.profile.commuteDetails.originAddress,
                  destinationAddress: props.profile.commuteDetails.destinationAddress,
                  matchedOriginStop: {
                    departureTimestamp: props.profile.commuteDetails.originDepartureTime,
                    arrivalTimestamp: props.profile.commuteDetails.originArrivalTime
                  },
                  matchedDestinationStop: {
                    departureTimestamp: props.profile.commuteDetails.destinationDepartureTime,
                    arrivalTimestamp: props.profile.commuteDetails.destinationArrivalTime
                  }
                }} />}
              </div>
            </div>
            <div className='rate-details'>
              <div className='breakdown bottom-left' role='group'>
                <div className='blue-notes'>
                  <div className='warning-icon'>
                    <img className='error-circle' src={DollarSign} aria-hidden='true' />
                  </div>
                  Your new subscription rate will take effect next month
                </div>
                <RateAccordion
                  isDesiredDriverRole={true}
                  rateMessage={null}
                  charge={props.selectedPlan.rate}
                />
                <TaxesAccordion taxes={props.selectedPlan.taxes} surcharges={props.selectedPlan.surcharges} total={parseFloat(props.selectedPlan.taxAmount) + parseFloat(props.selectedPlan.surchargeAmount)} />
                <div className='bottom' role='group'>
                  <div className='disclaimer' role='group'>
                    <p>
                      Recurring subscription. <br />
                      Rates subject to change. <br />
                      <span onClick={() => updatePDPopup(true)}>Learn More</span>
                    </p>
                  </div>
                  <div className='total' role='group'>
                    <div className='sub' >Total</div>
                    <div className='total-price' aria-label={`${driverRate.price} dollars and ${driverRate.decimal} cents total per month`}>
                      <div className='dollar'>$</div>
                      <div className='price'>{numberWithCommas(driverRate.price)}</div>
                      <div className='decimal'>.{driverRate.decimal}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='bottom-right'>
                <div>
                  <DatePickerCustom
                    selectedDay={selectedDay}
                    days={packageDays}
                    toggleCalendar={toggleCalendar}
                    onCalendarKeydown={onCalendarKeydown}
                    placeholder='Select Start Date'
                    label='Select a start date for your new subscription plan*'
                    hidePicker = {isJustSwitchingToDrive}
                    alternateText = 'Your Driver subscription will start once your driver application is approved'/>
                  <div aria-live='polite' role='alert' id='modal' className={"popup" + (isOpen ? " show" : " hide")}>
                    <Calendar
                      reference={node}
                      buttonText={'confirm start date'}
                      toggle={toggleCalendar}
                      selectedDay={selectedDay}
                      onConfirm={updateSelectedDay}
                      startDay={calendarInfo.startDay}
                      endDay={calendarInfo.endDay}
                      reset={isOpen}
                      enabledDays={enabledDays}
                    />
                  </div>
                </div>
                <div className='payment-cards'>
                  <div className='title'>
                    <div className='sub-title'>Payment Cards</div>
                    <div className='sub-text'>Recurring charges</div>
                  </div>
                  <div className='cards-container'>
                    {
                      paymentCards && paymentCards.map((card) => <PaymentCard key={card.partialAccountNumber} card={card} />)
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='button'>
              <button className='continue' disabled={isJustSwitchingToDrive ? false : !selectedDay} onClick={saveChangeSubscription}>save</button>
            </div>
            <DiscardChanges
              heading='Unsaved Changes'
              text='You’ll lose any unsaved changes if you leave now. Do you want to continue editing?'
              cancelButtonText='continue editing'
              confirmButtonText='leave'
              when={true}
              navigate={path => props.history.replace(path)}
              shouldBlockNavigation={location => {
                if (!!selectedDay && (!props.changed || props.changed === null)) {
                  if (location.pathname !== '/myprofile/change/plan/applyToDrive/summary') {
                    return true
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }}
              handleValidation={() => { }}
              reference={navigationAlertNode}
            />
          </div>
        </div>
      </div>
      {
        showConfirmation && <ChangeSubscriptionConfirmation isJustSwitchingToDrive={isJustSwitchingToDrive} startDay={subscriptionStartDay} close={closeChangeSubscriptionPopup} />
      }
      {
        showPDPopup && <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    driverApplicationData: state.profile.driverApplicationData,
    selectedPlan: state.profile.selectedPlan,
    contractInfo: state.joinCommute.contractInfo,
    changed: state.profile && state.profile.subscription && state.profile.subscription.typeChanged,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.GET_VANPOOL_RATES_INFO })
    },
    applyToDrive() {
      dispatch({ type: actionTypes.APPLY_TO_DRIVE })
    },
    changeSubscription(data) {
      dispatch({ type: actionTypes.CHANGE_SUBSCRIPTION_TYPE, data });
    },
    changeSubscriptionReset() {
      dispatch({ type: actionTypes.RESET_CHANGE_SUBSCRIPTION_TYPE });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeSubApplyToDriveSummary);