import { connect } from 'react-redux';
import ArrowLeft from '../../../../_styles/images/ArrowLeft.png';
import { useState } from 'react';
import * as actionTypes from '../../../../redux/actionTypes';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../../../../join-commute/payment-screen/dropdown/Dropdown';
import DiscardChanges from '../../common/discardChangesPopup/DiscardChanges';
import Loading from '../../../../shared/loading/Loading';
import analyticsService from '../../../../shared/analytics/analytics-service';


function UpdateLicense(props) {

  const [licenseNumber, updateLicenseNumber] = useState('');
  const [stateOfIssue, updateStateOfIssue] = useState('');
  const [isDataChanged, updateIsDataChanged] = useState(false);
  const [saveDisabled, updateSaveDisabled] = useState(true);
  const [isLoading, updateIsLoading] = useState(false);
  const [isApiCalled, updateIsApiCalled] = useState(false);

  useEffect(() => {
    if (!props.isLoaded) {
      updateIsLoading(true);
      props.init();
      analyticsService.getPageName({'pagename': 'drivers license/update'});
    }
  }, [])

  useEffect(() => {
    if (props.isLoaded) {
      updateIsLoading(false);
    }
  }, [props.isLoaded])

  function onLicenseChanged(val) {
    if (/^[a-zA-Z0-9]*$/.test(val) && val.length <= 19) {
      updateLicenseNumber(val);
    }
  }

  function updateLicense() {
    updateIsApiCalled(true);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": "save"
      }
  });
    props.updateLicenseAPI(
      {
        licenseNumber,
        stateOfIssue
      }
    );
  }

  useEffect(() => {
    if (isApiCalled) {
      navigateToProfileOverview();
    }
  }, [isApiCalled])

  function navigateToProfileOverview() {
    return props.history.push("/myprofile");
  }


  function checkDataChanged() {
    const isDataChanged = licenseNumber !== '' || stateOfIssue !== '';
    return isDataChanged;
  }

  useEffect(() => {
    const isDataChanged = licenseNumber !== '' || stateOfIssue !== '';
    updateIsDataChanged(isDataChanged);
    if (licenseNumber !== '' && stateOfIssue !== '') {
      updateSaveDisabled(false);
    } else {
      updateSaveDisabled(true);
    }
  }, [licenseNumber, stateOfIssue])

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={isLoading} />
      <div className='profile-edit-page edit-license-page'>
        <div className='view-container'>
          <div className='header'>
            <div className='backArrow'>
              <Link className="overview-link" onClick={props.history.goBack}>
                <img alt='go back' src={ArrowLeft}></img>
              </Link>
            </div>
            <div className='heading' tabIndex={0}>Driver’s License</div>
          </div>
          <div className='content-container'>
            <div className='small-heading' tabIndex={0}>Please provide your most recent license information.</div>
            <div className='field'>
              <div className='label'>
                <label tabIndex={0}>Driver’s License Number*</label>
              </div>
              <input placeholder='Driver’s license number' value={licenseNumber} onChange={(e) => onLicenseChanged(e.target.value)}></input>
            </div>
            <div className='state-date'>
              <div className='field'>
                <Dropdown
                  label="State Issued*"
                  placeholder='State'
                  onChange={(val) => updateStateOfIssue(val)}
                  options={props.states}
                  value={stateOfIssue}
                />
              </div>
            </div>
          </div>
          <div className='buttons'>
            <button className='save' disabled={saveDisabled} onClick={updateLicense}>Save</button>
          </div>
        </div>
        <DiscardChanges
          heading='Unsaved Changes'
          text='You’ll lose any unsaved changes if you leave now. Do you want to continue editing?'
          cancelButtonText='continue editing'
          confirmButtonText='leave'
          when={isDataChanged && !isApiCalled}
          navigate={path => props.history.push(path)}
          shouldBlockNavigation={location => {
          if (location.pathname !== '/myprofile/license/edit' || (location.pathname === '/myprofile' && !isApiCalled)) {
              return true
            }
          }
          }
          handleValidation={() => {
            checkDataChanged();
          }}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    states: state.profile.states,
    isLoaded: state.profile.isLoaded,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    updateLicenseAPI(data) {
      dispatch({ type: actionTypes.UPDATE_LICENSE, data });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateLicense);