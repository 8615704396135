import React, { Component } from 'react';
import { Close } from '../../shared/icons';
import { TimeInput } from '../../shared/TimeInput';
import dateUtility from '../../shared/dateUtility';
import analyticsService from '../../shared/analytics/analytics-service';


class DailyTripTimeModal extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      active : props.active,
      close: props.close ,
      homeBoundStartTime : props.homeBoundStartTime ? props.homeBoundStartTime : "00:00:00",
      homeBoundEndTime : props.homeBoundEndTime ?  props.homeBoundEndTime : "00:00:00",
      workBoundStartTime : props.workBoundStartTime ?  props.workBoundStartTime : "00:00:00",
      workBoundEndTime: props.workBoundEndTime ?  props.workBoundEndTime : "00:00:00",
      totalHomeTime:"00:00:00",
      totalWorkTime:"00:00:00",
      completeDate:"",
      workCommute: props.workCommute,
      homeCommute: props.homeCommute,
      homeBoundMiles: props.homeBoundMiles ? props.homeBoundMiles : 0,
      workBoundMiles: props.workBoundMiles ? props.workBoundMiles : 0,
      date: props.date,
      commuteWorkDisable: false,
      comuteHomeDisable: false,
      vanpoolers: props.vanpoolers,
      defaultTime: 0,
      defaultWorkTime: 0,
      defaultHomeTime: 0,
      longer: false,
      shorter: false,
      homeLonger: false,
      homeShorter: false,
      workLonger: false,
      workShorter: false
    }
  }

   componentDidMount() {
    this.CalculateHomeTime(this.props.homeBoundStartTime,this.props.homeBoundEndTime);
    this.CalculateWorkTime(this.props.workBoundStartTime,this.props.workBoundEndTime);
    this.getDayDetails(this.props.date);
    this.updateDisable(this.props.vanpoolers);
    this.getDefaultTime(this.props.workBoundStartTime,this.props.workBoundEndTime,this.props.homeBoundStartTime,this.props.homeBoundEndTime);
    analyticsService.analyticsProcessEvent({
      "event": "modal_view_generic",
      "context": {
        "event_action": "trip recording/daily trip details modal"
      }
    });
  }

   updateTime = () => {
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'save',
        "event_detail": {
          "modal": "trip recording/daily trip details modal"
        }
      }
    });
    this.props.change(this.state.date,this.state.workBoundStartTime,this.state.workBoundEndTime, this.state.homeBoundStartTime, this.state.homeBoundEndTime, this.state.homeBoundMiles, this.state.workBoundMiles);
    this.props.close();
  }

  updateDisable = (vanpoolers) => {
    var workDisable = false;
    var homeDisable = false;

    const allWork = vanpoolers.every(
      rider => rider.toWork 
    );
    const allHome = vanpoolers.every(
      rider => rider.toHome 
    );
  
    const isWork = vanpoolers.some( rider => rider.toWork === true)
    const isHome = vanpoolers.some( rider => rider.toHome === true)
  
    const userSelected = vanpoolers.some( rider => rider.singleSelected || rider.multiSelected)
  
    if (userSelected) {

      if (allWork) {
        workDisable = false;
       } else if (isWork) {
        workDisable = false;
       } else {
        workDisable = true;
       }
    //........................ home .............
       if (allHome) {
        homeDisable = false;
       } else if (isHome){
        homeDisable = false;
       }else {
        homeDisable = true;
       }

    } else {
      if(this.state.workCommute) {
        workDisable = false;
      } else {
        workDisable = true;
      }
      if (this.state.homeCommute) {
        homeDisable = false;
      } else {
        homeDisable = true;
      }
    }

    this.setState({commuteWorkDisable:workDisable , comuteHomeDisable:homeDisable}, () =>
      this.getDefaultTime(this.props.workBoundStartTime,this.props.workBoundEndTime,this.props.homeBoundStartTime,this.props.homeBoundEndTime)
      )

  }

  getDayDetails = (date) => {
    var completeDate = dateUtility.getDayDetails(date);
    this.setState({completeDate})
  }

 CalculateHomeTime = (StartTime,EndTime) => {
     var toHome = dateUtility.getDuration(StartTime, EndTime);
     var home = isNaN(toHome) ? 0 : toHome;
     var result = dateUtility.getHoursAndMinutesForTrip(home);
     this.setState({totalHomeTime:result}, () => {
      this.compareTime();
      }
      );
     
   };
  CalculateWorkTime = (StartTime,EndTime) => {
    var toWork = dateUtility.getDuration(StartTime, EndTime);
    var work = isNaN(toWork) ? 0 : toWork;
    var result = dateUtility.getHoursAndMinutesForTrip(work);
    this.setState({totalWorkTime:result}, () => {
      this.compareTime()
      }
      );
  }

  getDefaultTime = (workStart,workStop,homeStart,homeStop) => {
    var toWork = dateUtility.getDuration(workStart, workStop);
    var workTime = this.state.commuteWorkDisable ? 0 : toWork;
    var toHome = dateUtility.getDuration(homeStart, homeStop);
    var homeTime = this.state.comuteHomeDisable ? 0 : toHome;

    var totalTime = workTime + homeTime;
    this.setState({defaultTime: totalTime, defaultHomeTime: homeTime, defaultWorkTime: workTime}, () => { this.compareTime()});
  }

  closePopup = () => {
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": 'cancel',
        "event_detail": {
            "modal": "trip recording/daily trip details modal"
            }
      }
    });
    this.props.close();
  }

  componentDidUpdate(){
    let text = '';
    if(this.state.longer || this.state.shorter){
      text = 'To Home and To Work commutes are outside your average commute daily travel duration. Are the times and mileage entered above correct';
    } else if(this.state.homeLonger){
      text = 'To Home commute was considerably longer than your average commute. Are the times and mileage entered above correct';
    } else if(this.state.homeShorter){
      text = 'To Home commute was considerably shorter than your average commute. Are the times and mileage entered above correct';
    } else if(this.state.workLonger){
      text = 'To Work commute was considerably longer than your average commute. Are the times and mileage entered above correct';
    } else if(this.state.workShorter){
      text = 'To Work commute was considerably shorter than your average commute. Are the times and mileage entered above correct';
    }
    if(text !== ''){
      analyticsService.analyticsProcessEvent({
        "event": "modal_user_error",
        "context": {
          "event_action": text.toLowerCase(),
          "event_detail": {
              "modal": "trip recording daily trip details modal"
          }
        }
      });
    }
  };

  compareTime = () => {
    const {homeBoundStartTime, homeBoundEndTime, workBoundStartTime, workBoundEndTime}  = this.state;
    var toWork = dateUtility.getDuration(workBoundStartTime, workBoundEndTime);
    var toHome = dateUtility.getDuration(homeBoundStartTime, homeBoundEndTime);
    var workTime = this.state.commuteWorkDisable ? 0 : toWork;
    var homeTime = this.state.comuteHomeDisable ? 0 : toHome;
    var totalTime = workTime + homeTime;
    var defaultTime  = this.state.defaultTime;
    var defaultHomeTime = this.state.defaultHomeTime;
    var defaultWorkTime =  this.state.defaultWorkTime;
    var workMileLonger = 2 * this.props.workBoundMiles;
    var workMileShorter = this.props.workBoundMiles / 2;
    var homeMileLonger = 2 * this.props.homeBoundMiles;
    var homeMileShorter = this.props.homeBoundMiles / 2;
    var longer = 2 * defaultTime ;
    var shorter = defaultTime / 2;
    var toWorkLonger =  2 * defaultWorkTime;
    var toWorkedShorted =  defaultWorkTime / 2;
    if(!this.state.comuteHomeDisable) {
      var toHomeLonger =  2 * defaultHomeTime;
      var toHomeShorter =  defaultHomeTime / 2;
    }
 
    if(defaultTime > 0) {

    if(workTime > toWorkLonger &&  homeTime > toHomeLonger || this.state.workBoundMiles > workMileLonger && this.state.homeBoundMiles > homeMileLonger) {
      this.setState({longer: true, shorter: false, workShorter: false, workLonger: false, homeShorter: false, homeLonger: false});

    }else if (workTime < toWorkedShorted &&  homeTime < toHomeShorter || this.state.workBoundMiles <  workMileShorter && this.state.homeBoundMiles < homeMileShorter) {
      this.setState({longer: false, shorter: true, workShorter: false, workLonger: false, homeShorter: false, homeLonger: false});
    }
    else if (workTime > toWorkLonger &&  homeTime < toHomeShorter || this.state.workBoundMiles > workMileLonger && this.state.homeBoundMiles < homeMileShorter) {
      this.setState({longer: true, shorter: true, workShorter: false, workLonger: false, homeShorter: false, homeLonger: false});
    }
    else if (workTime < toWorkedShorted &&  homeTime > toHomeLonger || this.state.workBoundMiles <  workMileShorter && this.state.homeBoundMiles > homeMileLonger) {
      this.setState({longer: true, shorter: true, workShorter: false, workLonger: false, homeShorter: false, homeLonger: false});
    }
    else if(workTime > toWorkLonger || this.state.workBoundMiles > workMileLonger) {
      this.setState({longer: false, shorter: false, workShorter: false, workLonger: true, homeShorter: false, homeLonger: false });
    }
    else if (workTime < toWorkedShorted || this.state.workBoundMiles < workMileShorter){
      this.setState({longer: false, shorter: false, workShorter: true, workLonger: false, homeShorter: false, homeLonger: false });
    }
    else if(homeTime > toHomeLonger || this.state.homeBoundMiles > homeMileLonger) {
      this.setState({longer: false, shorter: false, workShorter: false, workLonger: false, homeShorter: false, homeLonger: true });
    }
    else if (homeTime < toHomeShorter || this.state.homeBoundMiles < homeMileShorter){
      this.setState({longer: false, shorter: false, workShorter: false, workLonger: false, homeShorter: true, homeLonger: false });
    }
    else {
      this.setState({longer: false, shorter: false, workShorter: false, workLonger: false, homeShorter: false, homeLonger: false });
    }
  } else {
    this.setState({longer: false, shorter: false,  workShorter: false, workLonger: false, homeShorter: false, homeLonger: false });
  }
  }

  render() {

    if (!this.state.active) {
      return null;
  }

    return (
      <div className="dailytripTimeModal-container" tabIndex={0} aria-live='polite' role='alert'>
        <div className="modal">
          <div className="header">
            <span onClick={this.props.close} className="close">
              <Close />
            </span>
            <div className="driveTimeHeader">
              Daily Trip Time: {this.state.completeDate}
            </div>
            <p>
              Edit the daily trip details to record the time and mileage for to work and to home
            </p>
          </div>
          <div className="body">
            <div className="labelContainer">
              <div className="timelabel"></div>
              <div className="startLabel" aria-label='start time'>Start</div>
              <div className="endLabel" aria-label='end time'>End</div>
              <div className="calTimelabel" aria-label='duration'>Time</div>
              <div className="mileageLabel" aria-label='miles'>Miles</div>
            </div>
            {/* ................................. */}
            <div className="upperContainer">
              <div className="timelabel">To Work</div>
              <div
                className={`start ${ !this.state.commuteWorkDisable ? '' : 'disabled'}`}
              >
                <TimeInput
                  id="toWorkStart"
                  aria-label='work start'
                  value={this.state.workBoundStartTime}
                  onSelected={workBoundStartTime =>
                    this.setState({ workBoundStartTime }, () =>
                      this.CalculateWorkTime(
                        this.state.workBoundStartTime,
                        this.state.workBoundEndTime
                      )
                    )
                  }
                />
              </div>
              <div
                className={`end ${!this.state.commuteWorkDisable? '' : 'disabled'}`}
              >
                <TimeInput
                  id="toWorkEnd"
                  aria-label='work end'
                  value={this.state.workBoundEndTime}
                  onSelected={workBoundEndTime =>
                    this.setState({ workBoundEndTime }, () =>
                      this.CalculateWorkTime(
                        this.state.workBoundStartTime,
                        this.state.workBoundEndTime
                      )
                    )
                  }
                />
              </div>
              <div className="time" aria-label='duration'>{this.state.totalWorkTime}</div>
              <div className={`mileage desktop-only ${ !this.state.commuteWorkDisable ? '' : 'disabled'}`}>
              <input
                      type="text"
                      className={`workMiles ${ !this.state.commuteWorkDisable ? '' : 'disabled'}`}
                      value={this.state.workBoundMiles}
                      onChange={(e) => this.setState({workBoundMiles: e.target.value }, () => setTimeout(() => {
                        this.compareTime();
                      }, 1000))}
                    />
                    <span className="milesUnits">MI</span>
                </div>
            </div>
            <div className="lowerContainer">
              <div className="timelabel">To Home</div>
              <div
                className={`start ${!this.state.comuteHomeDisable? '' : 'disabled'}`}
              >
                <TimeInput
                  id="toHomeStart"
                  aria-label='home start'
                  value={this.state.homeBoundStartTime}
                  onSelected={homeBoundStartTime =>
                    this.setState({ homeBoundStartTime }, () =>
                      this.CalculateHomeTime(
                        this.state.homeBoundStartTime,
                        this.state.homeBoundEndTime
                      )
                    )
                  }
                />
              </div>
              <div
                className={`end ${ !this.state.comuteHomeDisable ? '' : 'disabled'}`}
              >
                <TimeInput
                  id="toHomeEnd"
                  aria-label='home end'
                  value={this.state.homeBoundEndTime}
                  onSelected={homeBoundEndTime =>
                    this.setState({ homeBoundEndTime }, () =>
                      this.CalculateHomeTime(
                        this.state.homeBoundStartTime,
                        this.state.homeBoundEndTime
                      )
                    )
                  }
                />
              </div>
              <div className="time" aria-label='duration'>{this.state.totalHomeTime}</div>
              <div className={`mileage desktop-only ${ !this.state.comuteHomeDisable ? '' : 'disabled'}`}>
                  <input
                      type="text"
                      className={`homeMiles ${ !this.state.comuteHomeDisable ? '' : 'disabled'}`}
                      value={this.state.homeBoundMiles}
                      onChange={(e) => this.setState({homeBoundMiles: e.target.value}, () => setTimeout(() => {
                        this.compareTime()
                      }, 1000))}
                    />
                    <span className="milesUnits"  aria-label='miles Units'>MI</span>
              </div>
            </div>
            <div className="mobileView mobile-only">
            <div className="mobileLableContainer">
            <div className="timelabel"></div>
            <div className="mileageLabel">Miles</div>
            </div>
            <div className="mobileUpperContainer">
            <div className="timelabel">To Work</div>
            <div className={`mobileMileage ${ !this.state.commuteWorkDisable ? '' : 'disabled'}`}>
              <input
                      type="text"
                      className={`workMiles ${ !this.state.commuteWorkDisable ? '' : 'disabled'}`}
                      value={this.state.workBoundMiles}
                      onChange={(e) => this.setState({workBoundMiles: e.target.value },() => setTimeout(() => {
                        this.compareTime()
                      }, 1000))}
                    />
                    <span className="milesUnits" aria-label='miles Units' >MI</span>
            </div>
            </div>
            <div className="mobileLowerContainer">
            <div className="timelabel">To Home</div>
            <div className={`mobileMileage ${ !this.state.comuteHomeDisable ? '' : 'disabled'}`}>
                  <input
                      type="text"
                      className={`homeMiles ${ !this.state.comuteHomeDisable ? '' : 'disabled'}`}
                      value={this.state.homeBoundMiles}
                      onChange={(e) =>  this.setState({homeBoundMiles: e.target.value},() => setTimeout(() => {
                        this.compareTime()
                      }, 1000))}
                    />
                    <span className="milesUnits">MI</span>
              </div>
              </div>
            </div>
            <div className= {`footer ${ this.state.longer || this.state.shorter  ||  this.state.homeLonger || this.state.homeShorter || this.state.workLonger ||  this.state.workShorter ? 'warning' : ''} `}>
              {
              this.state.homeLonger ? 
              <p>To Home commute was considerably longer than your average commute. Are the times and mileage entered above correct</p>
              :
              null
              }
                 {
              this.state.homeShorter ? 
              <p>To Home commute was considerably shorter than your average commute. Are the times and mileage entered above correct</p>
              :
              null
              }

              {
              this.state.workLonger ? 
              <p>To Work commute was considerably longer than your average commute. Are the times and mileage entered above correct</p>
              :
              null
              }
                 {
              this.state.workShorter ? 
              <p>To Work commute was considerably shorter than your average commute. Are the times and mileage entered above correct</p>
              :
              null
              }  

              {
              this.state.longer  || this.state.shorter ? 
              <p>To Home and To Work commutes are outside your average commute daily travel duration. Are the times and mileage entered above correct</p>
              :
              null
              }
                 {/* {
              this.state.shorter ? 
              <p>To Home and To Work commutes are outside your average commute daily travel duration. Are the times and mileage entered above correct</p>
              :
              null
              }    */}
            <div className="tools response">
              <button className="button cancel" onClick={this.closePopup}>
                {'Cancel'}
              </button>
              <button
                className={'button confirm primary'}
                onClick={this.updateTime}
              >
                {'Save'}
              </button>
            </div>
          </div>
          </div>

        </div>
      </div>
    );
  }
}

  
  export default DailyTripTimeModal;