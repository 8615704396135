import React, { Component } from 'react';
import { connect } from 'react-redux';
import MainNavigation from './MainNavigation';
import Branding from './Branding';
import StationaryNav from './StationaryNav';
import ProfileNav from './ProfileNav';
import TopNav from './TopNav';
import MenuButtons from './MenuButtons';
import AggregateNav from './AggregateNav';
import IsVisible from '../permissions/IsVisible';
import { isAllowed, RestrictTo, RestrictFrom } from '../permissions/RestrictTo';
import {
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_USER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
} from '../permissions/permissions';
import analyticsService from '../analytics/analytics-service';

class NavigationMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropDownOpen: false,
      changingRoute: false
    };
    this.clickOutside = this.clickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.clickOutside, false);
  }

  clickOutside(event) {
    const profilePopup = document.getElementById('profile-popup');
    const profileIcon = document.getElementById('profile-icon');
    if (
      !profilePopup.contains(event.target) &&
      !profileIcon.contains(event.target) &&
      this.state.dropDownOpen
    ) {
      this.setState({ isOpen: false, dropDownOpen: false });
      this.props.onActive(false);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.clickOutside);
  }

  componentWillReceiveProps(newProps) {
    if (this.state.route !== newProps.navigation.location.pathname) {
      this.setState({
        isOpen: false,
        dropDownOpen: false,
        route: newProps.navigation.location.pathname
      });
      this.props.onActive(false);
    }
  }

  buttonClicked(shouldOpen) {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": "navigation:menu"
      }
    });
    this.setState({ isOpen: shouldOpen });
    this.props.onActive(shouldOpen);
  }

  render() {
    const { hideNavigation, profile, commuteProfile, profileImage, role } = this.props;
    let classForState = this.state.isOpen ? 'active' : '';
    let classForProfile = this.state.dropDownOpen ? 'active' : '';

    const isRegisteredUserWithoutTripRecording =
      isAllowed([PERMISSION_REGISTERED_USER]) &&
      !isAllowed([PERMISSION_TRIP_RECORDING]);
    const isAccountDashboardAdminWithVanpool =
      isAllowed([PERMISSION_ACCOUNT_DASHBOARD_ADMIN]) && profile.vanpoolId;
    const isAccountDashboardUser = isAllowed([
      PERMISSION_ACCOUNT_DASHBOARD_USER
    ]);

    if (!profile) {
      return null;
    }

    return (
      <div className={"side-navigation " + classForState}>
      <div className="transparent-bkrd"></div>
      <nav className={classForState}>
        <MenuButtons
          onClick={isOpen => {
            this.buttonClicked(isOpen);
          }}
        />

        <div className="top-bar">
          <Branding />

          <RestrictTo
            roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
            required={false}
          >
            <div className="aggregate-nav desktop-only">
              <AggregateNav />
            </div>
          </RestrictTo>
          <RestrictFrom
            roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
            required={false}
          >
            {/* <div className="aggregate-nav-filler"></div> */}
            <IsVisible
              permission={
                isAccountDashboardAdminWithVanpool ||
                (!isAccountDashboardUser &&
                  !hideNavigation &&
                  !isRegisteredUserWithoutTripRecording)
              }
            >
             <MainNavigation />
            </IsVisible>
          </RestrictFrom>
          <div className='right-section desktop-only'>
            <StationaryNav hideNavigation={hideNavigation} isRecentLeadSubmitted={profile.isRecentLeadSubmitted} />
            <ProfileNav
              hideNavigation={hideNavigation}
              firstName={profile.firstName}
              lastName={profile.lastName}
              dropDownOpen={this.state.dropDownOpen}
              onClick={() =>
                this.setState({ dropDownOpen: !this.state.dropDownOpen })
              }
              profileImage={profileImage}
              id='desktop-profile'
            />
          </div>
          <StationaryNav className='side-navigation-only' hideNavigation={hideNavigation} isRecentLeadSubmitted={profile.isRecentLeadSubmitted} />
          <ProfileNav
            className='side-navigation-only'
            hideNavigation={hideNavigation}
            firstName={profile.firstName}
            lastName={profile.lastName}
            dropDownOpen={this.state.dropDownOpen}
            profileImage={profileImage}
            onClick={() =>
              this.setState({ dropDownOpen: !this.state.dropDownOpen })
            }
            id='stationary-profile'
          />
          
        </div>

        <TopNav
          classForProfile={classForProfile}
          hideNavigation={hideNavigation}
          firstName={profile.firstName}
          lastName={profile.lastName}
          company={profile.employer || commuteProfile.commute.employer}
          profileImage={profileImage}
          isSubscriptionUser={profile.isSubscription}
          isDriver={role}
        />
      </nav>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigation: state.navigation,
    profile: state.userAuth,
    commuteProfile: state.commuteProfile,
    profileImage: state.profile.profileImage,
    role: state.profile.isDriver
  };
}

export default connect(
  mapStateToProps,
  null
)(NavigationMenu);
