import { connect } from 'react-redux';
import { TextImage } from '../../join-commute/onboarding/text-image/TextImage';
import analyticsService from '../../shared/analytics/analytics-service';
import DriverProfileImage from '../../driver-schedule/driver-month-collapsible/DriverProfileImage';

function RosterContainer(props) {
  const { participants, heading } = props;
  const total = participants.length;
  const showParticipants = participants.slice(0, (props.slice || 7));
  const remaining = total - showParticipants.length;

  function getTextForImage(fname, lname) {
    let nameStr = fname.charAt(0);
    nameStr += lname.charAt(0);
    return nameStr;
  }

  function handlceClick() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "see all"
      }
    });
    props.history.push('/vanpool/roster')
  }

  return (
    <div className="roster-container">
      <div className="header">
        <div className='left'>
          <div className="heading">{heading}</div>
          <div className='members'> <span className='count'>{total}</span> members</div>
        </div>
        <div
          className="see-all"
          onClick={() => handlceClick()}
        >
          See ALL
        </div>
      </div>
      <div className="participants">
        {showParticipants &&
          showParticipants.map(participant => (
            <DriverProfileImage driverInfo={participant} key={participant.participantId}/>
          ))}
        {remaining ? (
          <div className="image" aria-hidden="true">
            <TextImage text={'+' + remaining} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default connect(null, null)(RosterContainer);
