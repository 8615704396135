import {GET_BIN_NUMBERS, VERIFY_CARD} from '../../../redux/actionTypes';
import {useEffect, useState} from 'react';

import CardInformation from '../card-information/CardInformation';
import {MONTHS} from '../../helpers/commonHelper';
import {connect} from 'react-redux';
import creditCardService from '../../../shared/credit-card/creditCardService';
import {getSaveCardAPIInfo} from '../../helpers/vanHelpers';
import analyticsService from '../../../shared/analytics/analytics-service';
import Lock from '../../../_styles/images/lock.png';
import config from '../../../appConfig';

function BackupCard(props) {
  
  const [cardNumber,updateCardNumber] = useState('');
  const [cardValid,updateCardValid] = useState(true);
  const [expirationDate,updateExpirationDate] = useState({
    month:  MONTHS[new Date().getMonth()],
    year: new Date().getFullYear()
  });
  const [cvv,updateCvv] = useState('');
  const [cvvValid,updateCvvValid] = useState(true);
  const [zip,updateZip] = useState('');
  const [zipValid,updateZipValid] = useState(true);
  const [dateValid,updateDateValid] = useState(true);
  const [cardType,updateCardType] = useState('');
  const [buttonDisabled,updateButtonDisabled] = useState(true);
  const [isFederal,updateIsFederal] = useState(false);
  const [isCvvDisabled, updateCvvDisabled] = useState(true);
  const [previousCards, updatePreviousCards] = useState([]);
  const [commuteCharge,updateCommuteCharge] = useState(null);
  
  function onDateChange(type,value) {
    updateExpirationDate({
      ...expirationDate,
      [type]: value
    })
  }
  
  useEffect(() => {
    const today = new Date();
    if(expirationDate.month && expirationDate.year && expirationDate.month < today.getMonth()+1 && expirationDate.year <= today.getFullYear()){
      updateDateValid(false)
    } else {
      updateDateValid(true);
    }
  }, [expirationDate])

  function checkCVV(value) {
    let type = creditCardService.getCardTypeJoinCommute(cardNumber);
    if(creditCardService.isCvcValid(type.cvcLength,value)) {
      updateCvv(value);
    }
  }

  function checkZip(value) {
    if(/^\d{0,5}$/.test(value)) {
      updateZip(value);
      if(value.length === 5){
        updateZipValid(true);
      } else {
        updateZipValid(false);
      }
    }
  }

  function checkCardNumber(value) {
    if(value.length >= 7) {
      const isFederalCard = creditCardService.checkIfFederal(value, props.binNumbers);
      updateIsFederal(isFederalCard);
    } else {
      updateIsFederal(false);
    }
    value = value.split(' ').join('');
    if(creditCardService.isNumber(value)) {
      const type = creditCardService.getCardTypeJoinCommute(value);
      updateCardType(type);
      value = value.substr(0,Math.max.apply(Math,type.length));
      updateCardNumber(value);
    }
  }

  function onContinue() {
    let chargeAmount = 0;
    commuteCharge
    if(previousCards[0] && previousCards[0].chargeAmount !== commuteCharge){
      chargeAmount = commuteCharge - previousCards[0].chargeAmount;
    }
    
    const cardInfo = {
      cardNumber,
      cardType,
      cvv,
      expiry: expirationDate,
      zip,
      isFederal: false,
      isBackup: true,
      isDefault: false,
      chargeAmount
    }
    const saveCardInfo = getSaveCardAPIInfo(cardInfo);
    props.saveCard(saveCardInfo, cardInfo, props.history);
    analyticsService.analyticsProcessEvent({
      "event": "add_backup_card_click_continue",
      "context": {
        "event_action": "continue"
      }
    });
    props.history.push('/JoinCommute/summary');
  }

  function checkCardInfoValid(cardNumber,cardType,cvv) {
    const isCVVValid = cardType && cvv && cvv.length === cardType.cvcLength;
    if(cvv) {
      updateCvvValid(isCVVValid);
    }
    const isCardNumberValid = cardType && cardNumber && cardType.length.includes(cardNumber.length);
    const isLunh = creditCardService.luhn(cardNumber);
    if(cardNumber && cardType) {
      updateCardValid(isCardNumberValid && cardType.type !== '' && isLunh);
    } else {
      updateCardValid(true);
    }
    return isCVVValid && isCardNumberValid && isLunh;
  }

  useEffect(() => {
    const isValid = checkCardInfoValid(cardNumber,cardType,cvv,zip);
    if(cardNumber && cardType && cvv && zip && expirationDate && isValid && !isFederal && zipValid && dateValid) {
      updateButtonDisabled(false);
    } else {
      updateButtonDisabled(true);
    }
  },[cardNumber,cardType,cvv,zip,expirationDate, zipValid, dateValid]);
  
  useEffect(() => {
    props.getBinNumbers();
    analyticsService.pageInfo("add payment");
  },[])
  
  useEffect(() => {
    if(!cardValid || cardNumber == '') {
      checkCVV('');
      updateCvvDisabled(true);
    } else {
      updateCvvDisabled(false);
    }
  }, [cardValid, cardNumber])
  
  useEffect(() => {
    updatePreviousCards(props.cards);
  }, [props.cards]);
  
  useEffect(() => {
    updateCommuteCharge(props.commuteCharge);
  }, [props.commuteCharge])
  
  return (
    <div className='payment-page'>
      <div className='payment-container'>
        <div className='heading'>Add a backup card</div>
        {
        props.findAVan ?
        <div className='comment'>Your federal benefit card does not cover the cost of the subscription. Please provide a secondary payment card.</div>
        :
        <div className='comment'>In case your Federal Benefit card does not have enough funds to cover monthly charges, please add a backup card</div>
        }
        <div className='sub-heading'>Card Information</div>
        <CardInformation
          cardType={cardType}
          cardValid={cardValid}
          cardNumber={cardNumber}
          expirationDate={expirationDate}
          checkCardNumber={checkCardNumber}
          onDateChange={onDateChange}
          cvv={cvv}
          checkCVV={checkCVV}
          zip={zip}
          checkZip={checkZip}
          cvvValid={cvvValid}
          federalIssue={isFederal}
          isCvvDisabled={isCvvDisabled}
          zipValid={zipValid}
          dateValid={dateValid}/>

          {<div className={`legal-copy ${(isFederal || !zipValid || !cvvValid)? 'federal' : ''}`}>
            <div className='lock-image'>
              <img src={Lock} aria-hidden='true' />
            </div>
            <div className='legal-copy-text'>
              Your information will be submitted over a secure connection. <br/>
              For additional information, please review our
              {' '}
              <a
                href={config.privacyPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
                className='link'
              >
                Privacy Policy
              </a>
              {' '}and{' '}
              <a
                href={config.termsOfUseLink}
                target="_blank"
                rel="noopener noreferrer"
                className='link'
              >
                Terms of Use{' '}
              </a>
            </div>
          </div>}
        <button tabIndex={0} disabled={buttonDisabled} className='continue-button' onClick={onContinue}>continue</button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    binNumbers: state.joinCommute.payment.binNumbers,
    cards: state.joinCommute.payment.cards,
    commuteCharge: state.joinCommute.userCommuteData.charge,
    maxAmount: state.joinCommute.payment.maxAmount,
    isDesiredDriverRole: state.joinCommute.drivingScheduleData.isDesiredDriverRole,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBinNumbers() {
      dispatch({type: GET_BIN_NUMBERS})
    },
    saveCard(saveInfo, cardInfo, history) {
      dispatch({type: VERIFY_CARD, data: cardInfo, saveInfo, history})
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(BackupCard);