import React from 'react';
import Cell from '../../shared/Cell';
import dateUtility from '../../shared/dateUtility';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export default function OneTimeSuccessConfirmation({
  payment,
  vanpool,
  transactionId
}) {
  let selectedCard = payment.selectedCard;

  return (
    <div className="details">
      <div className="row">
        <Cell label="Type" value="One Time Payment" />
        <Cell label="Vanpool ID" value={vanpool} />
      </div>
      <div className="row">
        <Cell label="Payment Amount" value={'$' + numberWithCommas(payment.amount)} />
        <Cell
          label="Payment Date"
          value={dateUtility.parseDateAsMonthDayYear(new Date())}
        />
      </div>
      <div className="row">
        <Cell
          size="full"
          label="Payment Method"
          value={`${selectedCard.brand} ${selectedCard.account} - EXP ${selectedCard.exp}`}
        />
      </div>
      <div className="row">
        <Cell
          size="full"
          label="Transaction Number"
          value={`${transactionId}`}
        />
      </div>
    </div>
  );
}
