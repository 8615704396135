/* eslint-disable no-undef */
import React from 'react';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import {
  GoogleMap,
  withGoogleMap,
  DirectionsRenderer,
  Marker,
  withScriptjs
} from 'react-google-maps';
import dateUtility from './dateUtility';
import config from '../appConfig';
import logger from '../shared/logger';

const MapService = {};

const toGooglePoint = stop => {
  if (stop) {
    return new google.maps.LatLng(stop.latitude, stop.longitude);
  }
}

MapService.waitForGoogle = () =>
  new Promise(res => {
    let x = setInterval(() => {
      try {
        toGooglePoint({ latitude: 0, longitude: 0 });
        clearInterval(x);
        res();
      } catch (e) { }
    }, 10);
  });

MapService.getAddressAndLocation = async address => {
  await MapService.waitForGoogle();
  let geocode = await geocodeByAddress(address);
  //let latLng = await getLatLng(geocode[0]);
  let place_id = geocode[0].place_id;
  let { formatted_address, name, geometry } = await MapService.getPlaceDetails(
    geocode[0].place_id
  );
  let lattitude = geometry.location.lat();
  let longitude = geometry.location.lng();
  if ((lattitude == null || lattitude == "undefined" || lattitude == "") || (longitude == null || longitude == "undefined" || longitude == "")) {
    logger.serverLog({
      url: `${config.kongApiBaseUrl}/user/participant/contract`,
      data: { address },
      message: 'latitude and longitude are not returned from google',
      error: "latLngError"
    });
  }

  return {
    place_id,
    address: formatted_address,
    latitude: lattitude,
    longitude: longitude,
    description: name,
    geocode
  };
};

MapService.getAddressByLatLang = async (lat, lng) => {
  await MapService.waitForGoogle();
  let address = await geocodeByLatLng({ lat: lat, lng: lng });
  return address[0].formatted_address;
};

MapService.getPlaceDetails = placeId => {
  return MapService.waitForGoogle().then(() => {
    return new Promise((resolve, reject) => {
      let service = new google.maps.places.PlacesService(
        document.createElement('div')
      );

      service.getDetails({ placeId }, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          return resolve(result);
        }

        reject({ status });
      });
    });
  });
};

MapService.calculateRoute = directions => {
  return MapService.waitForGoogle().then(() => {
    return new Promise((resolve, reject) => {
      const DirectionsService = new google.maps.DirectionsService();
      directions = directions.filter(
        point => point.latitude && point.longitude
      );
      const start = directions[0];
      const end = directions[directions?.length - 1];
      const waypoints = directions.filter(
        stop => stop !== start && stop !== end
      );
      DirectionsService.route(
        {
          origin: toGooglePoint(start),
          destination: toGooglePoint(end),
          drivingOptions: { departureTime: dateUtility.nextMondayAt8Am() },
          waypoints: waypoints.map(stop => ({
            location: toGooglePoint(stop),
            stopover: true
          })),
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result);
          } else {
            reject({ status });
          }
        }
      );
    });
  });
};

MapService.calculateTodaysRoute = directions => {
  return MapService.waitForGoogle().then(() => {
    return new Promise((resolve, reject) => {
      const DirectionsService = new google.maps.DirectionsService();
      directions = directions.filter(
        point => {
          if (point.latitude && point.longitude) {
            if (point.stopOrder === 0 || point.stopOrder === -1) {
              return true;
            } else if (point?.todaysParticipants?.length > 0) {
              return true;
            }

          }
        });
      const start = directions[0];
      const end = directions[directions?.length - 1];
      const waypoints = directions.filter(
        stop => stop !== start && stop !== end
      );
      DirectionsService.route(
        {
          origin: toGooglePoint(start),
          destination: toGooglePoint(end),
          drivingOptions: { departureTime: dateUtility.nextMondayAt8Am() },
          waypoints: waypoints.map(stop => ({
            location: toGooglePoint(stop),
            stopover: true
          })),
          travelMode: google.maps.TravelMode.DRIVING
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            resolve(result);
          } else {
            reject({ status });
          }
        }
      );
    });
  });
};

MapService.MapWrapperComponent = withScriptjs(
  withGoogleMap(({ children, mapRef, ...rest }) => (
    <GoogleMap {...rest} ref={mapRef} options={{ maxZoom: 17, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}>
      {children}
    </GoogleMap>
  ))
);

const googleMapURL = `https://maps.googleapis.com/maps/api/js?${config.googleMapsCredentials}&libraries=places,geometry,drawing`;

MapService.MapWithDirections = ({ directions, hideTitle, ...rest }) => {
  const options = hideTitle ? { suppressInfoWindows: true, markerOptions: { title: '', clickable: false } } : {};
  return (
    <MapService.MapWrapperComponent
      defaultZoom={8}
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: '100%', width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...rest}
    >
      {directions ? <DirectionsRenderer directions={directions} options={options} /> : null}
    </MapService.MapWrapperComponent>
  );
};


MapService.MapWithDirectionsAndMarkers = ({ directions, markers, ...rest }) => {
  return (
    <MapService.MapWrapperComponent
      defaultZoom={15}
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: '100%', width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...rest}
    >
      {directions ? <DirectionsRenderer directions={directions} /> : null}
      {markers ?
        markers.map((m, index) => (
          <Marker
            key={index}
            position={{ lat: m.lat, lng: m.lng }}
            icon={{ url: m.icon, rotation: 80 }}

          // map= {rest.mapRef}
          />
        ))
        : null}
    </MapService.MapWrapperComponent>
  );
};

MapService.MapWithCustomMarkers = ({ directions, markers, ...rest }) => {
  return (
    <MapService.MapWrapperComponent
      defaultZoom={15}
      //zoom={15}
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: '100%', width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...rest}
    >
      {directions ? <DirectionsRenderer directions={directions} options={{ suppressMarkers: true }} /> : null}
      {markers ?
        markers.map((m, index) => (
          <Marker
            key={index}
            position={{ lat: m.lat, lng: m.lng }}
            icon={{ url: m.icon, rotation: 80 }}

          // map= {rest.mapRef}
          />
        ))
        : null}

    </MapService.MapWrapperComponent>
  );
};

function showMarkersOnMap(map, markers) {
  const bounds = new window.google.maps.LatLngBounds();
  markers.forEach((marker, i) =>
    bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng))
  );
  map.fitBounds(bounds);
}

MapService.MapWithMarkers = ({ markers = [], selected }) => {
  return (
    <MapService.MapWrapperComponent
      mapRef={map => map && markers && showMarkersOnMap(map, markers)}
      googleMapURL={googleMapURL}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: '100%', width: `100%` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    >
      {markers.map((marker, index) => (
        <Marker
          key={index}
          position={marker}
          visible={selected === -1 || selected === index}
          zIndex={index}
          label={String.fromCharCode(index + 'A'.charCodeAt())}
        />
      ))}
    </MapService.MapWrapperComponent>
  );
};

MapService.getStateFromGeocode = getCode => {
  const addressComponents = getCode[0].address_components;
  const stateObject = addressComponents.filter(comp => comp.types.includes('administrative_area_level_1'))
  return stateObject[0].short_name;
};

export default MapService;
