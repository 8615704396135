import React, { useEffect, useState } from 'react';
import { TextImage } from '../join-commute/onboarding/text-image/TextImage';
import { getMonthDayString, getTextForImage, getVanpoolerName } from '../join-commute/helpers/vanHelpers';
import blueHand from '../_styles/images/blue-hand.svg';
import drivingIcon from '../_styles/images/dashboard-driving-icon.svg';
import CommonToast from '../shared/components/common-toast-message/CommonToast';
import { connect } from 'react-redux';
import { getNextRidingDay, getValidVanpoolDays } from '../shared/route-service';
import { getDriveData } from '../driver-schedule/review-changes/ReviewDriverScheduleChanges';
import * as actionTypes from '../redux/actionTypes';
import { PERMISSION_SUBSCRIPTION_USER } from '../shared/permissions/permissions';
import { isAllowed, RestrictTo } from '../shared/permissions/RestrictTo';
import analyticsService from '../shared/analytics/analytics-service';

const errorToastMessage = 'There was an error while assigning a driver for the day. Please try again.';
const successToastMessage = 'Driver for the day assigned successfully.';
function StopTracker(props) {

  const { stop, index, isDriver, userCommuteDays, profile, vanpoolRoute } = props;
  const [driverData, updateDriverData] = useState(null);
  const [toastStatus, updateToastStatus] = useState();
  const [todaySchedule, updateTodaySchedule] = useState();
  const [showToast, updateShowToast] = useState(false);
  const [isTodaysCommute, updateIsTodaysCommute] = useState(true);
  const [dateString, updateDateString] = useState();

  useEffect(() => {
    if (!props.driverSchedule.loaded) {
      props.getDriverSchedule();
    }

    analyticsService.analyticsProcessEvent({
      "banner":{
        "banner_topic" : "next commute"
      }
    })
  }, [])

  useEffect(() => {
    if (props.driverSchedule && props.driverSchedule.fullArray) {
      //below is to check if today is still to be shown or the vanpool time is done today
      const validDays = getValidVanpoolDays(props.driverSchedule.fullArray, vanpoolRoute);
      const nextRidingDay = validDays && profile.vanpool.smallCommuteDays && getNextRidingDay(validDays, profile.vanpool.smallCommuteDays);
      if (nextRidingDay) {
        updateTodaySchedule(nextRidingDay);
        updateIsTodaysCommute(nextRidingDay.isPresentDay);
        updateDriverData(nextRidingDay.scheduledDriverData && nextRidingDay.scheduledDriverData[0] && nextRidingDay.scheduledDriverData[0].participantInfo);
        const dateStr = nextRidingDay.dateObject && getMonthDayString(nextRidingDay.dateObject);
        updateDateString(dateStr);
      }
    }
  }, [props.driverSchedule])


  function assignTodaysDrive() {
    const data = todaySchedule && getDriveData(todaySchedule.dateString, profile.participantId)
    props.updateDriverSchedule({ driverScheduleList: [data] });
  }

  useEffect(() => {
    if (props.updateScheduleSuccess === false) {
      updateToastStatus('error');
      updateShowToast(true);
      props.resetDriverScheduleUpdate();
    } else if (props.updateScheduleSuccess === true) {
      updateToastStatus('success');
      updateShowToast(true);
      props.resetDriverScheduleUpdate();
    }
  }, [props.updateScheduleSuccess])

  return (
    <div className="top stop-tracker" role='group'>
      <div className="next desktop-commute-only">
        <div className='next-container'><span>Next Stop</span></div>
        <RestrictTo  roles={[PERMISSION_SUBSCRIPTION_USER]} required={false} >
        {isTodaysCommute ? <div className='day-details'>Today’s Commute - {dateString}</div> : <div className='day-details'>Next Commute  - {dateString}</div>}
        </RestrictTo>
      </div>
      <div className="next mobile-commute-only">
        <div className='next-container'><span>Next Stop</span></div>
        { props.isSubscriptionUser ? <div className='day-details'>{dateString}</div> : null } 
      </div>
      <RestrictTo  roles={[PERMISSION_SUBSCRIPTION_USER]} required={false} >
        {isTodaysCommute ? <div className='hanging-info mobile-commute-only'>Today’s Commute</div> : <div className='hanging-info mobile-commute-only'>Next Commute</div>}
      </RestrictTo>
      <div className='right-top-section'>
        {stop && <div className="column">
          <div className="letter">
            {String.fromCharCode('A'.charCodeAt(0) + index)}
          </div>
          <div className='address-desc'>
            <span className="desc">{stop.description} -</span>
            {stop.address && <span className="addr desktop-text-only">{stop.address}</span>}
            {stop.address && <span className="addr tab-mobile-only">{stop.address.split(',', 1)}</span>}
          </div>
        </div>}
        <RestrictTo  roles={[PERMISSION_SUBSCRIPTION_USER]} required={false} >
        {driverData ?
          <div className='driver-info'>
            <div className='driver-image-container'>
              {driverData.profileImage ? <img className='profile-image' src={driverData.profileImage} alt={getTextForImage(driverData.firstName, driverData.lastName)} /> : <TextImage text={getTextForImage(driverData.firstName, driverData.lastName)} color={'rgba(15, 137, 82, 1)'} />}
              <img className='driving-icon' src={drivingIcon}></img>
            </div>
            {<div className='information'>
              <div className='vanpooler-name'>{getVanpoolerName(driverData.firstName, driverData.lastName)}</div>
              <div className='driving-text'>Driver</div>
            </div>}
          </div>
          :
          <div className='no-driver-section'>
            <div className='no-driver-text'>No driver assigned</div>
            {/*if user is driver and it is his commute day*/}
            {isDriver && todaySchedule && userCommuteDays.includes(todaySchedule.day) && <button className="assign-to-me" onClick={assignTodaysDrive}>
              <img src={blueHand} alt="assign to me" className="hand-icon" />
              Assign to me
            </button>}
          </div>
        }
        </RestrictTo>
      </div>
      <CommonToast
        message={toastStatus == 'error' ? errorToastMessage : successToastMessage}
        type={toastStatus}
        isOpen={showToast}
        closeToast={() => updateShowToast(false)}
        timeOut={6000} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    driverSchedule: state.driverSchedule,
    updateScheduleSuccess: state.driverSchedule.updateScheduleSuccess,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDriverSchedule() {
      if(isAllowed([PERMISSION_SUBSCRIPTION_USER])){
      dispatch({ type: actionTypes.GET_DRIVER_SCHEDULE });
      }
    },
    updateDriverSchedule(data) {
      dispatch({ type: actionTypes.UPDATE_DRIVER_SCHEDULE, data })
    },
    resetDriverScheduleUpdate() {
      dispatch({ type: actionTypes.UPDATE_DRIVER_SCHEDULE_RESET })
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StopTracker);
