import React, { Component } from 'react';
import { Info } from './icons';

export default class FieldInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  showInfo(event) {
    if (event.key === 'Enter') {
      this.setState({ open: !this.state.open });
    }
  }

  render() {
    return (
      <div
        className={`info ${this.state.open ? 'open' : ''}`}
        tabIndex={0}
        onClick={() => this.setState({ open: !this.state.open })}
        onKeyPress={event => this.showInfo(event)}
        aria-label='hot key info'
      >
        <Info  aria-label='info icon'/>
        <div className="message">{this.props.message}</div>
      </div>
    );
  }
}
