import PriceComponent from '../common/price-component/PriceComponent';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

export default function MoreSelect(props) {
  return (
    <div className='more' role='group'>
      <div className='tag'>
        <div className='text'>Ride 1 More Day</div>
      </div>
      {
        props.noOfDays <= 4
          ?
          <div className='select-heading'>{props.noOfDays}-Day Plan</div>
          :
          <div className='select-heading'>Unlimited Plan</div>
      }
      <div className='comment2'>
        <ul>
          <li>Lower per-day cost</li>
        </ul>
      </div>
      {props.selectedVan.isSubsidized && <SponsoredVanpool />}
      {props.isDriver && !props.wasRider
        ?
          (props.inviteId ?
            <div className='prices-container'>
              <PriceComponent
                small={true}
                charge={props.riderPrice} heading='Rider rate' selected={props.selectedOption === 1 && props.selectedType === 1} onSelect={() => props.updateSelection(1, 1)} />
              <PriceComponent small={true} heading='Driver rate' charge={props.driverPrice} selected={props.selectedOption === 1 && props.selectedType === 0} onSelect={() => props.updateSelection(1, 0)} />
            </div>
            :
              <div className='prices-container'>
                <PriceComponent small={false} charge={props.driverPrice} selected={props.selectedOption === 1 && props.selectedType === 0} onSelect={() => props.updateSelection(1, 0)} />
              </div>)
        :
        <div className='prices-container'>
          <PriceComponent
            small={true}
            charge={props.riderPrice} heading='Rider rate' selected={props.selectedOption === 1 && props.selectedType === 1} onSelect={() => props.updateSelection(1, 1)} />
          <PriceComponent small={true} charge={props.driverPrice} heading='Willing to drive?' selected={props.selectedOption === 1 && props.selectedType === 0} onSelect={() => props.updateSelection(1, 0)} />
        </div>
      }
      <div className='condition'>*All prices include fuel, maintenance, roadside assistance, insurance, applicable taxes and fees</div>
      {
        props.selectedOption === 1 && props.selectedType === 0 &&
        <div className='condition'>You can adjust your preferred driving days and frequency when you become an approved driver</div>
      }
    </div>
  );
} 